export const getLastMessage = user => {
  return user?.formattedChatHistory ? getMessagePreview(user.formattedChatHistory) : ''
}

const getMessagePreview = formattedChatHistory => {
  const message = JSON.parse(formattedChatHistory)

  if (isText(message)) return message.text
  if (isTemplate(message)) return 'Template'
  if (isAttachment(message)) return 'Attachment'
}

const isText = msg => {
  return msg.text
}

const isTemplate = msg => {
  return msg?.elements?.length
}

const isAttachment = msg => {
  return msg?.imageUrl || msg?.media?.length
}
