import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import classes from './styles.module.scss'

interface Props {
  id: string
  place?: string
  children: any
}

const Tooltip: FC<Props> = ({ children, id, place }) => {
  return (
    <ReactTooltip effect="solid" id={id} className={classes.tooltip} place={place}>
      {children}
    </ReactTooltip>
  )
}

export default Tooltip
