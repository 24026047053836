import React, {useMemo} from 'react'
import classes from './styles.module.scss'
import { PLATFORM_IMAGE, FACELESS_PICTURE } from './config'

const UserIcon = ({ image, platform, size }) => {
  const imageMemo = useMemo(() => image, [])

  return (
    <div
      className={classes.profileImage}
      style={{ backgroundImage: `url(${imageMemo}), url(${FACELESS_PICTURE})`, height: size, width: size }}>
      <img src={PLATFORM_IMAGE[platform]} alt="platform icon" className={classes.channelIcon} />
    </div>
  )
}

export default UserIcon
