import React, { useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Heading from '../../../../uiKit/texts/Heading'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import Input from '../../../../uiKit/inputs/Input'
import LaguageSelector from '../../../../uiKit/LaguageSelector/LaguageSelector'
import LogoDropzone from '../LogoDropzone'
import ColorSelector from '../ColorSelector'
import WidgetChatScreen from '../WidgetChatScreen'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { updateWidget } from '../../api/widget'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { deepCompare } from '../../../../helpers/deepCompare'

import classes from './styles.module.scss'
import { WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import Switch from '../../../../uiKit/Switch'

interface Props {
  match: any
  widgetSettings: WidgetSettingsType
}

const AppearanceTab: React.FC<Props> = ({ match, widgetSettings }) => {
  const [selectedLanguage, setSelectedLanguages] = useState({ label: 'English', value: 'en' })
  const [header, setHeader] = useState(widgetSettings?.languageConfigs[selectedLanguage.value]?.widgetName || '')
  const [subHeader, setSubHeader] = useState(widgetSettings?.nateraLanguageConfigs[selectedLanguage.value]?.widgetSubName || '')
  const [inputPlaceholder, setPlaceholder] = useState(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.inputPlaceholder || '',
  )
  const [errorsName, setErrorsName] = useState<string[]>([])
  const [errorsSubName, setErrorsSubName] = useState<string[]>([])
  const [errorsPlaceholder, setErrorsPlaceholder] = useState<string[]>([])

  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  const handleUpdateWidgetSettings = (value: string | boolean, field: string) => {
    widgetCopy.languageConfigs[selectedLanguage.value][field] = value
    widgetCopy.nateraLanguageConfigs[selectedLanguage.value][field] = value

    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleUpdateNateraWidgetSettings = (value: string | boolean, field: string) => {
    widgetCopy.nateraLanguageConfigs[selectedLanguage.value][field] = value

    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleUpdateName = (value: string) => {
    const isValid = validateField(value, setErrorsName)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'widgetName')
    }
  }

  const handleUpdateSubName = (value: string) => {
    const isValid = validateField(value, setErrorsSubName)

    if (isValid) {
      handleUpdateNateraWidgetSettings(value, 'widgetSubName')
    }
  }

  const handleUpdatePlaceholder = (value: string) => {
    const isValid = validateField(value, setErrorsPlaceholder)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'inputPlaceholder')
    }
  }

  const handleChangeName = event => {
    validateField(event.target.value, setErrorsName)
    setHeader(event.target.value)
  }

  const handleChangeSubName = event => {
    validateField(event.target.value, setErrorsSubName)
    setSubHeader(event.target.value)
  }

  const handlePlaceholder = event => {
    validateField(event.target.value, setErrorsPlaceholder)
    setPlaceholder(event.target.value)
  }

  const handleChangeLanguage = language => {
    setSelectedLanguages(language)
    setHeader(widgetSettings?.languageConfigs[language.value]?.widgetName)
    setSubHeader(widgetSettings?.nateraLanguageConfigs[language.value]?.widgetSubName)
    setPlaceholder(widgetSettings?.languageConfigs[language.value]?.inputPlaceholder)
  }

  const validateField = (value: string, callback: (a: any) => void) => {
    const errors = setErrors(value, 60)
    callback(errors)

    return !errors.length
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Heading>Appearance</Heading>
        <LaguageSelector onChange={handleChangeLanguage} value={selectedLanguage} />
      </div>

      <Paragraph style={{ color: SPUN_PEARL_GREY }}>
        Customize widget appearance that will be shown to the user based on his language
      </Paragraph>

      <div className={classes.body}>
        <div className={classes.form}>
          <LogoDropzone
            value={widgetSettings?.languageConfigs[selectedLanguage.value]?.imageUrl || ''}
            onChange={(value: string) => handleUpdateWidgetSettings(value, 'imageUrl')}
          />

          <ColorSelector
            value={widgetSettings?.languageConfigs[selectedLanguage.value]?.color || ''}
            onChange={(value: string) => handleUpdateWidgetSettings(value, 'color')}
          />

          <Input
            title="Widget name"
            placeholder="Chatbot"
            value={header || ''}
            onChange={handleChangeName}
            handleEnterPress={() => handleUpdateName(header)}
            onBlur={() => handleUpdateName(header)}
            error={!!errorsName.length}
            errorMessage={errorsName[0]}
          />

          <Input
            title="Widget sub name"
            placeholder="Natera's educational virtual assistant"
            value={subHeader || ''}
            onChange={handleChangeSubName}
            handleEnterPress={() => handleUpdateSubName(subHeader)}
            onBlur={() => handleUpdateSubName(subHeader)}
            error={!!errorsSubName.length}
            errorMessage={errorsSubName[0]}
          />

          <Input
            title="Input placeholder"
            placeholder="Write your reply"
            value={inputPlaceholder || ''}
            onChange={handlePlaceholder}
            handleEnterPress={() => handleUpdatePlaceholder(inputPlaceholder)}
            onBlur={() => handleUpdatePlaceholder(inputPlaceholder)}
            error={!!errorsPlaceholder.length}
            errorMessage={errorsPlaceholder[0]}
          />

          <div className={classes.switchersContainer}>
            <Switch
              label="Enable sound notifications"
              checked={widgetSettings?.languageConfigs[selectedLanguage.value]?.doEnableSoundNotifications}
              onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doEnableSoundNotifications')}
            />
            <Switch
              label="Show pop-up message preview"
              checked={widgetSettings?.languageConfigs[selectedLanguage.value]?.doShowPopupMessagePreview}
              onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doShowPopupMessagePreview')}
            />
            <Switch
              label="Ask for rating after conversation ends"
              checked={widgetSettings?.languageConfigs[selectedLanguage.value]?.doAskForRating}
              onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doAskForRating')}
            />
          </div>
        </div>
        {widgetSettings && (
          <WidgetChatScreen languageConfigs={widgetSettings.languageConfigs[selectedLanguage.value]} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(AppearanceTab))
