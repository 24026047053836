import React from 'react'

import DeleteAtom from '../DeleteAtom'
import { HOME_PATH } from 'configs'
import * as S from './AtomItem.style'

interface Props {
  atom: any
  activeBotId: number
  selectWelcomeAtom: () => void
}

export const AtomItem: React.FC<Props> = ({ atom, activeBotId, selectWelcomeAtom }) => {
  return (
    <S.AtomItem to={`${HOME_PATH}/bot/${activeBotId}/flows/${atom.id}`} data-testid={`atom-button-${atom.id}`}>
      {atom.name}
      {!atom.isHardCoded && <DeleteAtom atom={atom} activeBotId={activeBotId} selectWelcomeAtom={selectWelcomeAtom} />}
    </S.AtomItem>
  )
}
