import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import Modal from '../../../../../uiKit/Modal'
import { uploadBot } from '../../../api/export'
import { alertError, alertSuccess } from '../../../../../api'

import { styles } from './styles'
import { getBot } from '../../../../home/api/bots'
import LoaderSmall from '../../../../../uiKit/loaders/loaderSmall'

const UpdateBotModal = ({ classes, open, onClose, activeBot, formData }) => {
  const [loading, setLoading] = useState(false)

  const handleUpdate = () => {
    setLoading(true)
    uploadBot(activeBot.id, formData)
      .then(res => {
        setLoading(false)
        onClose()
        if (!res) {
          return alertError('Sorry but something is going wrong please ping support!')
        }
        if (res?.error?.message) {
          alertError(res?.error?.message)
        } else {
          alertSuccess('Chatbot version is updated successfully.')
        }
      })
      .then(() => {
        getBot(activeBot.id)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleClose = () => {
    setLoading(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {loading ? (
        <div className={classes.loaderContainer}>
          <LoaderSmall showLoader={loading} />
        </div>
      ) : (
        <div className={classes.body}>
          <div className={classes.text}>
            Are you sure you want to update your content?
          </div>
          <div className={classes.subText}>
            Previous content would be permanently deleted.
          </div>
          <div className={classes.alert}>Create dump before update.</div>
          <div className={classes.buttonsHolder}>
            <SubmitButton onClick={handleUpdate} empty title="Update" />
            <SubmitButton onClick={onClose} title="Cancel" />
          </div>
        </div>
      )}
    </Modal>
  )
}

UpdateBotModal.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(UpdateBotModal))
