import React, { useRef, useEffect } from 'react'

import { TextMessageType } from 'models/ChatMessagesTypes'
import * as S from './TextMessage.style'
import linkEditorListener from '../../helpers/linkEditorListener'

interface Props {
  message: TextMessageType
  isReceived: boolean
  isRtl: boolean
  highlight: boolean
}

export const TextMessage: React.FC<Props> = props => {
  const { message } = props

  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  return (
    <S.TextMessage ref={output} dangerouslySetInnerHTML={{ __html: message.text || message.postback }} {...props} />
  )
}
