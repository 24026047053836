import React, { useEffect, useState } from 'react'
import Input from '../inputs/Input'
import { ZERO_KEY, NINE_KEY, BACKSPACE_KEY, DELETE_KEY } from '../../constants/keyCodes'

import classes from './styles.module.scss'

const options = [
  {
    label: '5s',
    value: 5,
  },
  {
    label: '10s',
    value: 10,
  },
  {
    label: '15s',
    value: 15,
  },
]

const RadioButtonGroup = ({ title, value, errors, onChange, onBlur }) => {
  const [selectCustom, setSelectCustom] = useState(false)

  useEffect(() => {
    if (![5, 10, 15].includes(value)) setSelectCustom(true)
  }, [value])

  const handleChange = (value: string | number) => {
    setSelectCustom(false)
    onChange(value, false)
  }

  const handleSelectCustom = () => {
    setSelectCustom(true)
    onChange('', true)
  }

  const handleKeyDown = (event: any) => {
    if (
      (event.keyCode >= ZERO_KEY && event.keyCode <= NINE_KEY) ||
      [BACKSPACE_KEY, DELETE_KEY].includes(event.keyCode)
    ) {
      return event
    } else {
      event.preventDefault()
    }
  }

  return (
    <>
      {title && <p className={classes.inputTitle}>{title}</p>}
      <div className={classes.container}>
        {options.map(option => (
          <div
            key={option.value}
            className={option.value === value ? classes.optionActive : classes.option}
            onClick={() => handleChange(option.value)}>
            {option.label}
          </div>
        ))}
        <div className={selectCustom ? classes.optionActive : classes.option} onClick={handleSelectCustom}>
          Custom
        </div>
      </div>

      {selectCustom && (
        <Input
          placeholder="0"
          value={value}
          error={!!errors.length}
          errorMessage={errors[0]}
          onChange={(event: any) => onChange(event.target.value, true)}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
        />
      )}
    </>
  )
}
export default RadioButtonGroup
