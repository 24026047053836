import React, { useEffect, useRef } from 'react'

import * as S from './LastMsgText.style'
import linkEditorListener from '../../helpers/linkEditorListener'

interface Props {
  text: string
  isUnread: boolean
}

export const LastMsgText: React.FC<Props> = props => {
  const { text, isUnread } = props
  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  return <S.LastMsgText ref={output} isUnread={isUnread} dangerouslySetInnerHTML={{ __html: text }} />
}
