import {useState} from "react";

import {
  editCondition,
  createCondition,
  duplicateCondition
} from "../../api/Conditions";

import { useConditionsContext } from "../../context";

import { DUPLICATE, ADD, EDIT } from "../../../../../constants/string";

export const useConditionModal = (botId, modalAction) => {
  const { updateCondition, addCondition } = useConditionsContext();

  const request = condition => {
    if (modalAction === ADD) {
      return createCondition(condition, botId)
        .then(response => {
          if (response && response.id) {
            addCondition(response)
          }
        })
    }
    if (modalAction === EDIT) {
      return editCondition(condition, botId)
        .then(condition => {
          if (condition.id) {
            updateCondition(condition)
          }
          return condition
        })
    }
    if (modalAction === DUPLICATE) {
      return duplicateCondition(condition, botId)
        .then(condition => {
          if (!condition.id) {
            return
          }
          addCondition(condition)
        })
    }
  }

  return ({
    request
  })
}
