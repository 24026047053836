import {Error} from "@material-ui/icons";

import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson
} from "../../../../api/index";
import {BASE_URL} from "../../../../configs";

export function getAllConditionsWithoutAtoms(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/condition/flows`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET"
  })
    .then((response) => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError("Sorry but something going wrong please ping support!"));
}


export const getFlow = (conditionId, botId) =>
  fetch(`${BASE_URL}/bot/${botId}/condition/${conditionId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET"
  })
    .then((response) => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError("Sorry but something going wrong please ping support!"));


export function duplicateCondition(flow, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/condition/duplicate`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "POST",
    body: JSON.stringify(flow)
  })
    .then((response) => {
      if (response.status < 400) return logoutOnRequestOrResponseJson(response);
      alertError('Error');
      throw Error;
    })
    .then(flow => {
      alertSuccess("Condition is duplicated successfully.");
      return flow
    })
    .catch(() => alertError('Something went wrong'));
}


export function deleteConditionAPI(conditionId, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/condition/${conditionId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "DELETE",
  })
    .then((response) => logoutOnRequestOrResponseJson(response))
    .then(() => {
      alertSuccess("Condition is deleted successfully.");
    });
}


export function editCondition(condition, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/condition`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "PUT",
    body: JSON.stringify(condition),
  })
    .then((response) => logoutOnRequestOrResponseJson(response))
    .then((json) => {
      alertSuccess("Condition is updated successfully.");
      return json;
    });
}

export function createCondition(condition, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/condition`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "POST",
    body: JSON.stringify(condition),
  })
    .then((response) => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess("Condition is created successfully.");
      return json
    })
    .catch(() => alertError('Something went wrong'));
}

export const getTypeConditionFlowForDropdowns = botId =>
  fetch(`${BASE_URL}/bot/${botId}/condition/flows/info`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET",
  })
    .then((response) => logoutOnRequestOrResponseJson(response))


