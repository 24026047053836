import { BASE_URL, BACKEND_URL } from 'configs'
import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from 'api'
import { AGENT_JOINED, AGENT_LEFT } from 'constants/widgetActionsType'

export function getMessages(chatId, page) {
  const params = new URLSearchParams({
    page,
    size: '20',
    sort: 'id,desc',
  })

  return fetch(`${BASE_URL}/support/chat/${chatId}?${params}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function closeUserRequest(json, botId, chatId) {
  return fetch(`${BASE_URL}/support/backUserToBot`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).then(() => sendAction(AGENT_LEFT, botId, chatId))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function assignSupportRequest(botId, json, chatId) {
  return fetch(`${BASE_URL}/support/assign`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  })
    .then(() => sendAction(AGENT_JOINED, botId, chatId))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function getAdminsList(botId) {
  return fetch(`${BASE_URL}/support/admins/${botId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function setMessageRead(chatId, messageId) {
  return fetch(`${BASE_URL}/support/read/user/${chatId}/message/${messageId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
  }).catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function sendAction(actionType, botId, chatId) {
  const event = {
    action: {
      type: actionType,
    },
    chatId: chatId.toString()
  }

  return fetch(`${BACKEND_URL}/widget/${botId}/chat/${chatId}/action`, {
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(event),
  })
}
