import {useState, useMemo} from "react";

import {
  getFlow,
  getAllConditionsWithoutAtoms
} from "./api/Conditions";

import { useDropdownsSettings } from "./useDropdownsSettings";

const useContextHandler = botId => {
  const { dropdownsSettings, getDropdownsSettings } = useDropdownsSettings(botId);
  const [conditions, setConditions] = useState([])

  const getConditions = () => {
    getAllConditionsWithoutAtoms(botId)
      .then(response => setConditions(response))
  }

  const getFlowCondition = (conditionId, botId) =>
    getFlow(conditionId, botId).then(response => {
      updateCondition(response)
    })

  const updateConditionAtom = (conditionId, newAtom) => {
    const condition = conditions.filter(condition =>
      condition.id === conditionId)[0];
    const newAtoms = condition.atoms.map(atom =>
      atom.id === newAtom.id ? newAtom : atom);
    const newCondition = {
      ...condition,
      atoms: newAtoms
    }

    updateCondition(newCondition)
  }

  const updateCondition = newCondition => {
    const newConditions = conditions.map(condition =>
      condition.id === newCondition.id ? newCondition : condition);

    setConditions(newConditions)
  }

  const deleteCondition = deletedCondition => {
    const newConditions = conditions.filter(condition =>
      condition.id !== deletedCondition.id);

    setConditions(newConditions)
  }

  const addCondition = newCondition => setConditions([
    ...conditions,
    newCondition
  ])

  const value = useMemo(() => ({
    conditions,
    addCondition,
    getConditions,
    deleteCondition,
    updateCondition,
    getFlowCondition,
    dropdownsSettings,
    updateConditionAtom,
    getDropdownsSettings
  }), [JSON.stringify(dropdownsSettings), JSON.stringify(conditions)]);

  return ({
    value,
  });
}

export {
  useContextHandler,
};
