import React from 'react';
import {withStyles} from "@material-ui/core";
import * as ReactDOM from "react-dom";
import {isStringEmpty} from "../../helpers/isStringEmpty";
import {paramsPattern} from "../../constants/paramsPattern";
import {pasteCleanTextContent} from "../../helpers/pasteCleanTextContent";

const styles = theme => ({
    input: {
        borderTop: '1px solid #979797',
        borderBottom: '1px solid #979797',
        height: 120,
        overflow: 'scroll',
        fontSize: 14,
        padding: '10px 10px 0',
        outline: 'none',
        background: '#fff',
        whiteSpace: 'pre-line'
    },
    '@global': {
        '.parameter': {
            background: '#deeafd',
            borderRadius: '3px'
        }
    }
});

class ContentEditable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }
    }

    componentDidMount() {
        this.selectListener();
        this.linkEditorListener();
        this.pasteListener();
        this.props.setContainer(ReactDOM.findDOMNode(this.input));
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.text !== prevState.text) {
            this.setState({text: this.props.text});
            this.linkEditorListener();
        }
    };

    selectListener = async () => {
        ReactDOM.findDOMNode(this.input).addEventListener('mouseup', (e) => {
            if (!window.getSelection() || !window.getSelection().anchorNode) return;
            let selectedString = window.getSelection().toString();
            const {showLinkIcon} = this.props;
            if (showLinkIcon && window.getSelection().focusOffset === 2) {
                this.props.hideLinkIcon();
            } else if (!isStringEmpty(selectedString)) {
                this.props.openLinkIcon();
            } else if (showLinkIcon && isStringEmpty(selectedString)) {
                this.props.hideLinkIcon();
            }
        });
    };

    pasteListener = () => {
        ReactDOM.findDOMNode(this.input).addEventListener('paste', (event) => {
            if (!paramsPattern.test(event.clipboardData.getData('Text'))) {
                pasteCleanTextContent(event);
            }
        });
    };

    linkEditorListener = () => {
        let editedLinks = [...ReactDOM.findDOMNode(this.input).querySelectorAll('.linkEdited')];
        editedLinks.length > 0 && editedLinks.forEach(link => link.onclick = (e) => this.props.selectLink(e.target));
    };


    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.text !== prevState.text) {
            this.input.innerHTML = prevProps.text;
            return this.input.innerHTML;
        } else if (isStringEmpty(this.input.innerHTML) && !prevProps.text) {
            this.input.innerHTML = prevProps.text;
            return this.input.innerHTML;
        }

        return null;
    };

    render() {
        const {classes, handleText} = this.props;
        return (
            <div ref={node => this.input = node}
                 className={classes.input}
                 onInput={(e) => handleText(e, this.input.getBoundingClientRect())}
                 contentEditable={true}
                 style={this.props.style}
            >
            </div>
        )
    };
}


export default withStyles(styles, {withTheme: true})(ContentEditable);