import React from "react";

export const FlowHeader = (props) => {
  const {currentFlow, backToFlows, classes} = props;

  return (
    <div className={classes.container}>
      <p className={classes.pointHolder}><span onClick={backToFlows && backToFlows}>Flows</span>
        {currentFlow &&  <span>/ <span className={classes.activePoint}> {currentFlow.flowCode}</span></span>}
      </p>
    </div>
  )
};
