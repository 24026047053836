import React, { useMemo, useState } from 'react'
import { Pencil } from '../../../../uiKit/icons/Pencil'
import { DropdownSettingsModal } from '../DropdownSettingsModal/DropdownSettingsModal'
import { TextMessageType } from '../../../../models/ChatMessagesTypes'
import * as S from './AddDropdownItem.style'
import { IconButton } from '@material-ui/core'

interface Props {
  message: TextMessageType
  updateMessage: (message: TextMessageType, index?: number) => void
}

export const AddDropdownItem: React.FC<Props> = ({ message, updateMessage }) => {
  const [isOpenDropdownModal, setIsOpenDropdownModal] = useState(false)
  const isInitializedDropdown = useMemo(() => !!message.dropdown, [message.dropdown])
  const [showDelete, setShowDelete] = useState(false)

  const dropdownText = useMemo(() => {
    return isInitializedDropdown ? <><Pencil /> {'Edit dropdown'} </> : 'Add dropdown'
  }, [isInitializedDropdown])

  const openDropdownModalEditor = () => {
    setIsOpenDropdownModal(true)
  }

  const addNewDropdown = () => {
    const newMessage = JSON.parse(JSON.stringify(message))

    if (newMessage.dropdown == null) {
      newMessage.dropdown = {
        items: [
          {
            option: '',
            attributes: []
          }
        ]
      }
      updateMessage(newMessage)
    }
  }

  const handleSaveModal = (newMessage) => {
    updateMessage(newMessage)
    setIsOpenDropdownModal(false)
  }

  const dropdownHandler = () => isInitializedDropdown ? openDropdownModalEditor() : addNewDropdown()

  const onDeleteHandler = () => {
    const newMessage = JSON.parse(JSON.stringify(message))
    delete newMessage.dropdown
    updateMessage(newMessage)
  }

  return (
    <S.DropdownWrapper
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <S.DropdownButton onClick={dropdownHandler} isInitializedDropdown={isInitializedDropdown}>
        {dropdownText}
      </S.DropdownButton>
      <IconButton
        aria-label="Delete"
        style={
          (showDelete && isInitializedDropdown)
            ? {
              height: '36px',
              position: 'absolute',
              top: 0,
              right: -5,
            }
            : { display: 'none' }
        }
        onClick={onDeleteHandler}>
        <img
          style={{ height: 30 }}
          src="/images/platform/delete.svg"
          alt="delete"
        />
      </IconButton>

      {isOpenDropdownModal && (
        <DropdownSettingsModal
          open={isOpenDropdownModal}
          message={message}
          onClose={() => setIsOpenDropdownModal(false)}
          handleSaveModal={handleSaveModal}
        />
      )}
    </S.DropdownWrapper>
  )
}
