import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { alertSuccess, alertError } from '../../../api'
import { createWebview, updateWebview } from '../api/webview'

import DropzoneWebview from './DropzoneWebview'
import Input from '../../../uiKit/inputs/Input'
import InputWithParamsAndLinks from '../../../uiKit/inputs/InputWithParamsAndLinks'
import SubmitButton from '../../../uiKit/buttons/SubmitButton'
import {isStringEmpty} from "../../../helpers/isStringEmpty";
import {LinkTemplateModalProvider} from "../../../contexts/LinkTemplateModalProvider/LinkTemplateModalProvider";

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
    cursor: 'pointer',
    '&>button:first-child': {
      marginRight: 16,
    },
  },
  container: {
    position: 'relative',
  },
})

const inputLinksContentEditableStyle = {
  border: '1px solid #C7CAD6',
  color: '#616581',
  padding: '14px 24px',
  outline: 'none',
  position: 'inherit',
  fontSize: '14px',
  height: '100%',
  maxHeight: '180px',
  borderRadius: '10px',
}

const inputLinksContainerStyle = {
  maxHeight: '180px',
  height: 'auto',
}

class CreateWebviewTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newWebview: {
        botId: '',
        text: '',
        name: '',
        imageUrl: null,
      },
    }
  }

  handleChange = (name, value) => {
    const { newWebview } = this.state
    newWebview[name] = value
    this.updateWebviewData(newWebview)
  }

  updateWebviewData = webview => {
    const { match } = this.props
    this.setState({
      newWebview: {
        ...webview,
        botId: match.params.botId
      }
    })
  }

  componentDidMount() {
    const { webview } = this.props
    if (webview) this.updateWebviewData(webview)
  }

  createWebview = () => {
    const { webview, language } = this.props
    let { newWebview } = this.state
    if (!webview) {
      if (isStringEmpty(newWebview.name)) {
        alertError('Name field should not be empty')
        return
      }
      createWebview(newWebview, language)
        .then(this.props.onClose)
        .catch(() => alertError('Something went wrong'))
    } else {
      if (isStringEmpty(newWebview.name)) {
        alertError('Name field should not be empty')
        return
      }
      updateWebview(newWebview, language)
        .then(() => {
          alertSuccess('Webview updated successfully')
          this.props.updateWebviews(newWebview, this.props.idxWebview)

          this.props.onClose()
        })
        .catch(() => alertError('Something went wrong'))
    }
  }

  render() {
    const { classes, onClose, webview, params } = this.props
    const { text, name, imageUrl } = this.state.newWebview
    return (
      <div className={classes.container}>
        <Input
          value={name}
          onChange={e => this.handleChange('name', e.target.value)}
          title="New webview name"
          placeholder="Enter new webview name"
        />
        <DropzoneWebview
          value={imageUrl}
          onChange={imageUrl => this.handleChange('imageUrl', imageUrl)}
        />
        <LinkTemplateModalProvider>
          <InputWithParamsAndLinks
            params={params}
            value={text}
            onCloseModal={this.props.onClose}
            placeholder="Enter your text here"
            onChange={e => this.handleChange('text', e)}
            containerStyles={inputLinksContainerStyle}
            inputLinksContentEditableStyle={inputLinksContentEditableStyle}
          />
        </LinkTemplateModalProvider>
        <div className={classes.buttonContainer}>
          <SubmitButton
            size="lg"
            title={webview ? 'Edit' : 'Create'}
            onClick={this.createWebview}
          />
          <SubmitButton
            size="lg"
            title="Cancel"
            onClick={onClose}
            empty={true}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.params,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(CreateWebviewTemplate),
  ),
)
