import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import classes from './styles.module.scss'

interface Params {
  setDate: any
  startDate: any
  endDate: any
}

const DateRange: React.FC<Params> = ({ setDate, startDate, endDate }) => {
  const saveChanges = (event, picker) => {
    setDate(picker.startDate, picker.endDate)
  }

  return (
    <DateRangePicker
      className={classes.dateRangePickerContainer}
      startDate={startDate}
      endDate={endDate}
      onApply={saveChanges}>
      <input
        className={classes.input}
        type="text"
        name="daterange"
        value={`${startDate.format('YYYY-MM-DD')} - ${endDate.format(
          'YYYY-MM-DD',
        )}`}
      />
    </DateRangePicker>
  )
}
export default DateRange