import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { alertSuccess } from '../../../api'
import { CopyIcon } from '../../../uiKit/icons/Icons'

const styles = theme => ({
  webviewContainer: {
    display: 'flex',
    padding: '24px',
    cursor: 'pointer',
    borderRadius: 10,
    justifyContent: 'flex-start',
    '&>div:first-child': {
      flexBasis: '35%',
      fontSize: 14,
      lineHeight: '19px',
      color: '#3A3F62',
    },
    '&:nth-child(2n)': {
      background: 'rgba(90, 152, 247, 0.08)',
    },
    '& svg': {
      opacity: '0',
    },
    '&:hover  svg': {
      opacity: '1',
    },
    '& .delete': {
      fontSize: 14,
      color: '#FF624C',
      cursor: 'pointer',
      flexBasis: '5%',
      marginLeft: 'auto',
      opacity: '0',
    },
    '&:hover  .delete': {
      opacity: '1',
    },
  },
  url: {
    width: 'max-content',
    color: '#B0B2C0',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
  icon: {
    flexBasis: '5%',
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  copyIcon: {
    margin: 0,
  },
  copyContainer: {
    display: 'flex',
    flexBasis: '45%',
    cursor: 'pointer',
    borderRadius: 10,
    justifyContent: 'flex-start',
  },
})

const WebviewItem = props => {
  const { webview, classes, deleteWebview, selectWebview } = props
  return (
    <div className={classes.webviewContainer} onClick={selectWebview}>
      <div>{webview.name}</div>
      <CopyToClipboard
        text={webview.link}
        onCopy={() => alertSuccess('Copied!')}>
        <div
          onClick={e => e.stopPropagation()}
          className={classes.copyContainer}>
          <div className={classes.url}>{webview.link}</div>
          <div className={`${classes.icon} ${classes.copyIcon}`}>
            <CopyIcon />
          </div>
        </div>
      </CopyToClipboard>
      <div
        className="delete"
        onClick={e => {
          e.stopPropagation()
          deleteWebview()
        }}>
        Delete
      </div>
    </div>
  )
}

WebviewItem.propTypes = {
  webview: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(WebviewItem)
