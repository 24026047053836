import React from "react";

import { ConditionsProvider } from "./context";
import { ConditionsTabContexts } from "./ConditionsTab/ConditionsTabContexts";

export const Conditions = props => (
  <ConditionsProvider botId={props.match.params.botId}>
    <ConditionsTabContexts />
  </ConditionsProvider>
)
