export const styles = () => ({
  activePoint: {
    color: '#5A98F7 !important',
  },
  pointHolder: {
    marginBottom: 18,
    '& > span': {
      fontSize: 16,
      lineHeight: '19px',
      color: '#3A3F62',
      cursor: 'pointer'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});