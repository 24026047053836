import React, { ReactEventHandler, useState } from 'react'

import ModalTemplate from '../../../../uiKit/ModalTemplate'
import PostbackAttributes from '../PostbackAttributes/PostbackAttributes'

import classes from '../../../widget/components/AddGreetingModal/styles.module.scss'
import { DropdownItemType, TextMessageType } from '../../../../models/ChatMessagesTypes'
import { DropdownInputItem } from '../DropdownInputItem/DropdownInputItem'
import { deepCopyFunction } from 'helpers/deepCopyFunction'

interface Props {
  open: boolean
  message: TextMessageType
  onClose: ReactEventHandler
  handleSaveModal: (m: TextMessageType) => void
}

export const DropdownSettingsModal: React.FC<Props> = ({ open, message, onClose, handleSaveModal }) => {
  const [dropdownItems, setDropdownItems] = useState<DropdownItemType[]>(deepCopyFunction(message.dropdown.items))
  const [saveTime, setSaveTime] = useState(new Date())

  const validateDropdown = () => {
    const hasEmptyOption = dropdownItems.some(item => !item.option)
    const hasEmptyAttributes = dropdownItems.some(item => {
      if (!item.attributes || !item.attributes.length) {
        return false
      }

      return item.attributes.some(attribute => !attribute.name)
    })

    return hasEmptyOption || hasEmptyAttributes
  }

  const handleSave = () => {
    const error = validateDropdown()
    setSaveTime(new Date())

    if (!error) {
      const messageCopy = deepCopyFunction(message)
      messageCopy.dropdown.items = dropdownItems
      handleSaveModal(messageCopy)
    }
  }

  const handleUpdateAttributes = (attributes, index) => {
    const dropdownsCopy = [ ...dropdownItems ]
    dropdownsCopy[index].attributes = attributes
    setDropdownItems(dropdownsCopy)
  }

  return (
    <ModalTemplate open={open} title="Edit dropdown" onClose={onClose} onSave={handleSave}>
      <div>
        <div className={classes.body} id='scrollable'>
          {dropdownItems?.map((dropdownItem: DropdownItemType, index) => (
            <div className={classes.wrapper} key={index}>
              <DropdownInputItem
                dropdownItem={dropdownItem}
                dropdownItems={dropdownItems}
                setDropdownItems={setDropdownItems}
                dropdownIndex={index}
                saveTime={saveTime}
              />

              <PostbackAttributes
                atomAttributes={dropdownItem.attributes}
                handleUpdateAttributes={(attributes) => handleUpdateAttributes(attributes, index)}
                saveDropdownAttributeTime={saveTime}
              />
              <div className={classes.dropdownItemSeparator} />
            </div>
          ))}
        </div>
      </div>
    </ModalTemplate>
  )
}
