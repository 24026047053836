import React from "react";

import { useContextHandler } from "./useContextHandler";

export const ConditionsContext = React.createContext({});

export const ConditionsProvider = props => {
  const { value } = useContextHandler(props.botId);

  return (
    <ConditionsContext.Provider value={value}>
      {props.children}
    </ConditionsContext.Provider>
  );
};

export function useConditionsContext() {
  const context = React.useContext(ConditionsContext);

  if (!context) {
    throw new Error("useConditionsContext must be inside ConditionsProvider");
  }

  return context;
}
