import React from 'react'
import { useState } from 'react'
import { withStyles } from '@material-ui/core'
import CreateWebviewTemplate from './CreateWebviewTemplate'
import Modal from '../../../uiKit/Modal'

const styles = theme => ({
  createBtn: {
    outline: 0,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.primary.main,
    padding: '13px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: 10,
    background: '#f6fafe',
    cursor: 'pointer',
  },
})

const CreateWebview = props => {
  const [open, toggleOpen] = useState(false)

  const { classes, language } = props
  return (
    <div>
      <button onClick={() => toggleOpen(true)} className={classes.createBtn}>
        + Create Webview
      </button>
      <Modal
        onClose={() => toggleOpen(false)}
        open={open}
        title="Create a new webview">
        <CreateWebviewTemplate
          language={language}
          onClose={() => toggleOpen(false)}
        />
      </Modal>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(CreateWebview)
