import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import classes from '../../../widget/components/AddGreetingModal/styles.module.scss'
import { DropdownItemType } from '../../../../models/ChatMessagesTypes'
import Input from '../../../../uiKit/inputs/Input'
import { usePrevious } from '../../../../hooks/usePrevious'

import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'


interface Props {
  dropdownItem: DropdownItemType
  dropdownItems: DropdownItemType[]
  setDropdownItems: (d: DropdownItemType[]) => void
  dropdownIndex: number
  saveTime: Date
}

export const DropdownInputItem: React.FC<Props> = (
  {
    dropdownItem,
    dropdownItems,
    setDropdownItems,
    dropdownIndex,
    saveTime
  }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const prevSaveTime = usePrevious(saveTime)

  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      validateInput()
    }
  }, [saveTime])

  const handleUpdateDropdownValue = (e) => {
    const value = e.target.value || e.target.innerText
    const dropdownItemsCopy = [ ...dropdownItems ]
    dropdownItemsCopy[dropdownIndex].option = value
    setDropdownItems(dropdownItemsCopy)
    validateInput()
  }

  const validateInput = () => {
    if (!dropdownItem.option) {
      setErrorMessage('This field can’t be empty')
    } else {
      setErrorMessage('')
    }
  }

  const handleAddDropdown = () => {
    const dropdownItemsCopy = [ ...dropdownItems ]
    const dropdownItem = {
      option: '',
      attributes: []
    }
    dropdownItemsCopy.push(dropdownItem)
    setDropdownItems(dropdownItemsCopy)
  }

  const handleDeleteDropdownItem = (index: number) => {
    const dropdownItemsCopy = [ ...dropdownItems ]
    dropdownItemsCopy.splice(index, 1)
    setDropdownItems(dropdownItemsCopy)
  }

  return (
    <div className={classnames(
      classes.container,
      (dropdownItems.length === 1 && classes.dropdownOneItem)
    )}>
      <Input
        title="Dropdown list items"
        placeholder="Enter text"
        value={dropdownItem.option || ''}
        onChange={(e) => handleUpdateDropdownValue(e)}
        onBlur={() => validateInput()}
        error={!!errorMessage}
        errorMessage={errorMessage}
      />

      {(dropdownIndex === dropdownItems.length - 1) && (
        <div className={classes.addButton} onClick={handleAddDropdown}>
          <PlusIcon />
        </div>
      )}

      {!(dropdownItems.length === 1) && (
        <div className={classes.iconWrap} onClick={() => handleDeleteDropdownItem(dropdownIndex)}>
          <TrashIcon />
        </div>
      )}
    </div>
  )
}
