import React, {useState, useEffect} from 'react';
import Select from "react-select";

import SubmitButton from "../../../../../uiKit/buttons/SubmitButton";

import { useConditionsContext } from "../../context";

import { useConditionModal } from "./useConditionModal";
import { DUPLICATE, EDIT } from "../../../../../constants/string";

export const ConditionModal = props => {
  const {classes} = props;
  const [data, setData] = useState({});
  const { request } = useConditionModal(props.botId, props.modalAction);
  const { dropdownsSettings, getDropdownsSettings } = useConditionsContext();
  const getSelectValueFromData = value => ({
    label: value ? value : "",
    value: value ? value : ""
  })

  const isEditAction = props.modalAction === EDIT;
  const isDuplicateAction = props.modalAction === DUPLICATE;

  useEffect(() => {
    if (props.flow) {
      isEditAction && setData({
        flowType: props.flow.flowType,
        flowCode: props.flow.flowCode,
      })
      isDuplicateAction && setData({
        flowType: props.flow.flowType,
      })
    }
  }, [props.flow])

  const isDisabledButton = data.flowType && data.flowCode;

  const getFlowTypesForSelect = () => dropdownsSettings.flowTypes &&
    dropdownsSettings.flowTypes.map(flow => ({
      value: flow,
      label: flow
    }))

  const getFlowCodesForSelect = () => dropdownsSettings.availableFlowCodes &&
    dropdownsSettings.availableFlowCodes.map(code => ({
      value: code,
      label: code
    }))

  const handleChangeSelect = (value, name) => setData({
    ...data,
    [name]: value,
  })

  const handleSubmit = () => {
    const sendData = {...data};

    if (props.flow && props.flow.id) {
      if (isDuplicateAction) {
        sendData.sourceConditionId = props.flow.id
      }
      if (isEditAction) {
        sendData.id = props.flow.id
      }
    }

    request(sendData)
      .then(() => getDropdownsSettings())
      .then(() => props.onClose());
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div className={classes.selectWrapper}>
          <p>Flow Type</p>
          <Select
            simpleValue
            placeholder="123"
            options={getFlowTypesForSelect()}
            value={getSelectValueFromData(data.flowType)}
            onChange={(e) => handleChangeSelect(e.value, 'flowType')}
            isOptionDisabled={(option) => option.disabled === 'true'}
          />
        </div>
        <div className={classes.selectWrapper}>
          <p>Condition's flow</p>
          <Select
            simpleValue
            placeholder="123"
            options={getFlowCodesForSelect()}
            value={getSelectValueFromData(data.flowCode)}
            onChange={(e) => handleChangeSelect(e.value, 'flowCode')}
            isOptionDisabled={(option) => option.disabled === 'true'}
          />
        </div>
      </div>

      <div className={classes.btnContainer}>
        <SubmitButton
          onClick={handleSubmit}
          size='lg'
          title={props.modalAction}
          disabled={!isDisabledButton}
        />
      </div>
    </div>
  )
}
