import React, { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FACEBOOK, TWILIO, WHATSAPP } from '../../../constants/attributeTypes'
import AttributesList from '../../AttributesList/AttributesList'
import { CreateLinkIcon } from '../../icons/Icons'
import { LinkTemplateModal } from '../LinkTemplateModal'
import { AttributeIcon } from '../../icons/AttributeIcon'
import { container } from '../../InputWithParams/config'
import { ARROW_DOWN_KEY, ARROW_LEFT_KEY, ARROW_RIGHT_KEY, ARROW_UP_KEY, ENTER_KEY, SPACE_KEY } from 'constants/keyCodes'
import { createAttribute } from 'tabs/settings/api/attributes'

import { createATag } from './helpers/createATag/createATag'
import { handleCtrlC } from './helpers/handleCtrlC'
import { handleCtrlV } from './helpers/handleCtrlV'
import { getPopupPosition } from './helpers/getPopupPosition'
import { backEndProcessing } from './helpers/backEndProcessing/backEndProcessing'
import { generateAttribute } from './helpers/generateAttribute'
import { setCaretAfterNode } from './helpers/setCaretAfterNode'
import { checkForSelection } from './helpers/checkForSelection'
import { frontDataProcessing } from './helpers/frontDataProcessing/frontDataProcessing'
import { selectionChangeListener } from './helpers/selectionChangeListener'
import { addAttributeToAfterText } from './helpers/addAttributeToAfterText'
import { addAttributeToEmptyField } from './helpers/addAttributeToEmptyField'
import { calculateSelectedChildNodeIndex } from './helpers/calculateSelectedChildNodeIndex'
import { removeExtraBracketsAfterAttributeIsAdded } from './helpers/removeExtraBracketsAfterAttributeIsAdded'

import classes from './styles.module.scss'
import { addAttributeToAfterTag } from './helpers/addAttributeToAfterTag'

import { checkAttributeForExistingAndConnection } from './helpers/checkAttributeForExistingAndConnection'
import { AttributeItemType } from '../../../models/AttributeTypes'
import { LinkType } from '../../../models/LinkTypes'
import { MessagesContext } from 'tabs/flowBuilder/components/MessagesWrap/MessagesWrap'

interface InputWithParamsAndLinksProps {
  onChange: any
  currentValue: any
  rtl?: any
  activeBotId: number
  attributes: AttributeItemType[]
  activeBot?: any
  twilioCredentials: any
}

const InputWithParamsAndLinks: React.FC<InputWithParamsAndLinksProps> = ({
  currentValue,
  onChange,
  activeBotId,
  activeBot,
  twilioCredentials,
  attributes,
  rtl,
}) => {
  const [node, setNode] = useState<any>(null)
  const [value, setValue] = useState<any>(currentValue)
  const [ranger, setRange] = useState<any>(null)
  const [show, setShow] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [link, setLink] = useState<LinkType | null>(null)
  const [caretPos, setCaretPos] = useState<any>(undefined)
  const [selectedAttribute, setSelectAttribute] = useState(1)
  const [anchorNode, setAnchorNode] = useState<any>(undefined)
  const [selectionText, setSelectionText] = useState<string>('')
  const [searchAttribute, setSearchAttribute] = useState<any>('')
  const [caretNodePos, setCaretNodePos] = useState<any>(undefined)
  const [notConnectedAttributes, setNotConnectedAttributes] = useState([])

  const { selectedLanguage } = useContext(MessagesContext)
  const inputRef = useRef(null)
  const attributeIcon = useRef(null)
  const attributeList = useRef(null)
  const isFacebookConnected = activeBot?.messengerConfigs?.accessToken
  const isWhatsappConnected = activeBot?.dialog360WhatsAppConfigs?.apiKey
  const isTwilioConnected = twilioCredentials?.connected

  useEffect(() => {
    if (currentValue !== value) {
      setValue(currentValue)
    }
  }, [currentValue])

  useEffect(() => {
    const attributesCopy = attributes
      .filter(({ type }) => {
        return (
          (type === FACEBOOK && !isFacebookConnected) ||
          (type === WHATSAPP && !isWhatsappConnected) ||
          (type === TWILIO && !isTwilioConnected)
        )
      })
      .map(({ name }) => {
        return `{{${name}}}`
      })

    setNotConnectedAttributes(attributesCopy)
  }, [attributes])

  useEffect(() => {
    const selectionchangeListener = () => selectionChangeListener(inputRef, setSelectionText)
    // eslint-disable-next-line max-len
    const pasteListener = event =>
      handleCtrlV(event, attributes, inputRef, caretNodePos, setValue, setNode, activeBotId, true)
    const copyListener = event => handleCtrlC(event, true)

    inputRef.current.innerHTML = backEndProcessing(currentValue, attributes)
    document.addEventListener('selectionchange', selectionchangeListener)
    inputRef.current.addEventListener('paste', pasteListener)
    inputRef.current.addEventListener('copy', copyListener)
    return () => document.removeEventListener('selectionchange', selectionchangeListener)
  }, [selectedLanguage.value])

  useEffect(() => {
    setValue(inputRef?.current?.innerHTML)
  }, [inputRef?.current?.innerHTML])

  useEffect(() => {
    inputRef.current.addEventListener('click', (e) => {
      inputRef.current.childNodes.forEach(nodeItem => {
        if (nodeItem?.tagName?.toLowerCase() === 'a' && e.target === nodeItem) {
          handleOnClickLink(nodeItem)
        }
      })
    })
  }, [])

  useEffect(() => {
    if (node) setCaretAfterNode(node)
  }, [node])

  useEffect(() => {
    onChange(frontDataProcessing(value))
  }, [value])

  useEffect(() => {
    setSelectAttribute(1)
  }, [show])

  const createLink = (link, isEditing: boolean) => {
    const linkData = {
      dataHref: link.link,
      dataExtension: link.messengerExtensions,
      innerText: link.text,
      onclick: () => checkForSelection(handleOnClickLink, spanLink),
    }
    const spanLink = createATag(linkData)

    if (isEditing) node?.remove()
    ranger.deleteContents()
    ranger.insertNode(spanLink)
    onChange(frontDataProcessing(inputRef.current?.innerHTML))
    setNode(spanLink)
  }

  const handleOnClickLink = el => {
    const range = document.createRange()
    range.selectNodeContents(el)
    setNode(el)
    handleModalOpening(range, el.innerText, el.getAttribute('data-href'), JSON.parse(el.getAttribute('data-extension')))
  }

  const handleOnClickLinkButton = () => {
    const selection = window.getSelection().toString()
    if (selection) {
      handleModalOpening(window.getSelection().getRangeAt(0), selection, '', false)
    }
  }

  const handleModalOpening = (range, text: string, link: string, messengerExtensions) => {
    setLink({ link, text, messengerExtensions })
    setRange(range)
    setIsOpen(true)
  }

  const handleChange = e => {
    const caretCurPos = window.getSelection().getRangeAt(0)
    const caretCurPosIndex = caretCurPos.startOffset
    const anchorCurNode = caretCurPos.commonAncestorContainer
    const inputCurVal = e.target.innerHTML

    checkForOpeningAndClosingBrackets(inputRef.current, anchorCurNode, caretCurPosIndex)
    updateShowPopupWhileOnChange()
    setValue(inputCurVal)
    setIsEmpty(!inputRef.current?.innerHTML)

    saveCaretPos()
  }

  const checkForOpeningAndClosingBrackets = (inputEl, textEl, caretPosition) => {
    const inputElValue = inputEl.innerHTML
    const indexOffOpenBrackets = inputElValue.indexOf('{{')
    const indexOffCloseBrackets = inputElValue.indexOf('}}', indexOffOpenBrackets)

    if (indexOffOpenBrackets >= 0) {
      if (indexOffCloseBrackets > 0) {
        const startIndex = indexOffOpenBrackets + 2
        const deleteCount = indexOffCloseBrackets - indexOffOpenBrackets - 2
        const attrArr = inputElValue.split('').splice(startIndex, deleteCount)
        const attrVal = [...attrArr].join('')
        addAttribute(attrVal)
      } else {
        const start = textEl.nodeValue?.indexOf('{{') + 2
        const searchVal = textEl.nodeValue?.substring(start, caretPosition)
        setSearchAttribute(searchVal)
      }
    }
  }

  const updateShowPopupWhileOnChange = () => {
    inputRef.current.childNodes.forEach(nodeItem => {
      if (nodeItem.nodeType === Node.TEXT_NODE) {
        if (nodeItem.nodeValue?.includes('{{')) {
          setShow(true)
          setShowSearch(false)
        } else if (nodeItem.nodeValue?.includes('}}') || show) {
          setShow(false)
        }
      }
    })
  }

  const saveCaretPos = (e = null) => {
    e?.preventDefault()
    setAnchorNode(window.getSelection().anchorNode)

    if (window.getSelection().anchorNode === inputRef.current) {
      setCaretNodePos(window.getSelection().anchorOffset)
    } else {
      setCaretPos(window.getSelection().getRangeAt(0))
    }
  }

  const handleCloseAttributeList = () => {
    inputRef.current.childNodes.forEach(nodeItem => {
      if (nodeItem.nodeType === Node.TEXT_NODE && nodeItem.nodeValue?.includes('{{')) {
        nodeItem.nodeValue = nodeItem.nodeValue?.replace('{{' + searchAttribute, '')
      }
    })
    setShow(false)
  }

  const onKeyDown = event => {
    handleSpace(event)
    handleVerticalArrows(event)
    handleEnter(event)
    handleHorizontalArrows(event)
    handleCtrlC(event, false)
    handleCtrlV(event, attributes, inputRef, caretNodePos, setValue, setNode, activeBotId, false)
  }

  const handleSpace = event => {
    const key = event.keyCode || event.charCode

    if (!showSearch && key === SPACE_KEY && show) {
      event.preventDefault()
    }
  }

  const handleVerticalArrows = event => {
    const key = event.keyCode || event.charCode

    if (key === ARROW_DOWN_KEY || key === ARROW_UP_KEY) {
      event.preventDefault()

      if (show) {
        updateAttributeListScroll(key)
      }
      return true
    }
  }

  const updateAttributeListScroll = direction => {
    const childNodes = attributeList?.current?.childNodes
    const selectedChildNodeIndex = calculateSelectedChildNodeIndex(direction, attributeList, selectedAttribute)
    const selectedChildNode = childNodes[selectedChildNodeIndex]

    selectedChildNode?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
    setSelectAttribute(selectedChildNodeIndex)
  }

  const handleEnter = event => {
    const key = event.keyCode || event.charCode

    if (key === ENTER_KEY) {
      event.preventDefault()

      if (show) {
        const selectedValue = attributeList?.current?.childNodes[selectedAttribute]?.innerText || searchAttribute
        if (selectedValue) {
          addAttribute(selectedValue)
        }
      } else {
        document.execCommand('insertLineBreak')
      }
    }
  }

  const handleHorizontalArrows = event => {
    const key = event.keyCode || event.charCode

    if ((key === ARROW_LEFT_KEY || key === ARROW_RIGHT_KEY) && show) {
      event.preventDefault()
    }
  }

  const onKeyPress = event => {
    const key = event.keyCode || event.charCode

    inputRef.current.childNodes.forEach(nodeItem => {
      if (nodeItem.nodeType === Node.TEXT_NODE && nodeItem.nodeValue?.includes('{{') && key === 123) {
        event.preventDefault()
      }
    })
  }

  const addAttribute = attribute => {
    if (attribute) {
      const caretCurPos = window.getSelection().getRangeAt(0).startOffset
      if (!checkAttributeForExistingAndConnection(inputRef, notConnectedAttributes, attribute, caretCurPos)) {
        const currentAttribute = attributes.find(({ name }) => name === attribute)
        const newAttr = generateAttribute(currentAttribute, attribute)
        if (!currentAttribute) createAttribute(activeBotId, { name: attribute })
        selectAttributeAddType(newAttr)
        setNode(newAttr)
        removeExtraBracketsAfterAttributeIsAdded(inputRef, newAttr, attribute)
        setCaretAfterNode(newAttr)
      }
    } else {
      removeExtraBrackets()
    }
    setShowSearch(true)
    setSearchAttribute('')
    setShow(false)
    setValue(inputRef?.current.innerHTML)
  }

  const removeExtraBrackets = () => {
    inputRef.current.childNodes.forEach(nodeItem => {
      if (nodeItem?.nodeValue?.includes('{{}}')) {
        nodeItem.nodeValue = nodeItem.nodeValue.replace('{{}}', '')
        setCaretAfterNode(nodeItem)
      }
    })
  }

  const selectAttributeAddType = newAttr => {
    if (anchorNode === inputRef.current && caretNodePos) {
      addAttributeToAfterTag(newAttr, inputRef, caretNodePos)
    } else if (anchorNode?.nodeValue) {
      addAttributeToAfterText(newAttr, anchorNode, caretPos, inputRef)
    } else if ((!caretPos || (!caretPos.startOffset && !caretPos.endOffset)) && !caretNodePos) {
      addAttributeToEmptyField(newAttr, inputRef)
    }
  }

  const handleShowAttributeList = () => {
    setShow(true)
    setShowSearch(true)
  }

  const childNodes = inputRef?.current?.childNodes

  const getTextAligningForInput = (): CSSProperties => useMemo(() => ({ textAlign: rtl ? 'right' : 'left' }), [rtl])

  return (
    <div className={classes.container}>
      {!isEmpty && selectionText && document.activeElement === inputRef?.current && (
        <div className={classes.editLink} onMouseDown={() => checkForSelection(handleOnClickLinkButton)}>
          <CreateLinkIcon />
        </div>
      )}
      <div
        dir={rtl && 'rtl'}
        style={getTextAligningForInput()}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        contentEditable={true}
        onInput={handleChange}
        className={classes.input}
      />
      <AttributesList
        show={show}
        onKeyDown={onKeyDown}
        addParam={addAttribute}
        listRef={attributeList}
        showSearch={showSearch}
        searchValue={searchAttribute}
        onClose={handleCloseAttributeList}
        selectedAttribute={selectedAttribute}
        updateSelectedAttribute={setSelectAttribute}
        position={getPopupPosition(attributeIcon?.current?.getBoundingClientRect(), container)}
      />
      <LinkTemplateModal
        link={link}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        createLink={createLink}
        setCaret={() => setCaretAfterNode(childNodes[childNodes.length - 1])}
      />
      {((!selectionText && document.activeElement === inputRef?.current) || show) && (
        <div className={classes.iconsWrap}>
          <span
            ref={attributeIcon}
            className={classes.icon}
            onMouseDown={saveCaretPos}
            onClick={handleShowAttributeList}>
            <AttributeIcon />
          </span>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
  activeBot: state.activeBot,
  twilioCredentials: state.twilioCredentials,
})

export default withRouter(connect(mapStateToProps)(InputWithParamsAndLinks))
