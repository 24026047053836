export const styles = theme => ({
  flowName: {
    fontSize: 14,
    color: theme.palette.primary.text,
    margin: 0,
    width: '100%',
    cursor: 'pointer'
  },
  flow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 0',
    borderBottom: '1px solid #0000001a',
  },
  popover: {
    width: '145px',
    height: '93px'
  },
  button: {
    fontSize: '14px',
    color: '#3A3F62',
    display: 'flex',
    alignItems: 'center',
    padding: '11px 24px',
    height: '50%',
    '&:hover': {
      background: 'rgba(90, 152, 247, 0.1)'
    }
  },
  btnIcon: {
    paddingLeft: '20px'
  },
  '@global': {
    '.rootElement > * ':
      {
        border: '1px solid #D7D8DF',
        boxShadow: '0px 1px 5px rgba(19, 69, 186, 0.241904)',
        borderRadius: '10px',
      },
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: "24px 70px",
  },
  modalPaper: {
    overflow: "visible !important"
  }
});