//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import DetectableOverflow from 'react-detectable-overflow'

import { customStyles } from './styles'
import { connect } from 'react-redux'
import {checkLocation} from "../../static";
import {getAtomsWithDisease, getAtomsWithConditions} from "../../tabs/flowBuilder/api/atom";
import classes from './styles.module.scss'
import {OptionItemType} from '../../models/MessageTypes'

interface Props {
  flows: any
  atom: any
  activeBot: any
  value: string
  touched?: boolean
  onFocus?(event): void
  onChange(value: OptionItemType): void
}

const AtomSelect: React.FC<Props> = ({ flows, atom, activeBot, value, touched, onFocus, onChange }) => {
  const [options, setOptions]: [OptionItemType[], any] = useState([])
  const [isOverflowed, setIsOverflowed]: [boolean, any] = useState(false)

  const atomValue = useMemo(() => {
    return options.find(({ value: val, label }) => val === value || label === value)
  }, [value, options])

  useEffect(() => {
    const options: OptionItemType[] = []

    const botId = activeBot.id
    const isDisease = checkLocation('/diseases');
    const isCondition = checkLocation('/conditions');

    if (isDisease || isCondition) {
      pushConditionOrDiseaseAtoms(isDisease, isCondition, botId, options)
    } else {
      if (flows) {

        flows.forEach(({ atoms }: any) => {
          if (atoms) {
            atoms.forEach(({ name, id }: any) => {
              options.push({ label: name, value: id })
            })
          }
        })

        setOptions(options)
      }
    }
  }, [flows, atom])

  const pushConditionOrDiseaseAtoms = (isDisease, isCondition, botId, options) => {
    if (!atom.id) return
    if (isDisease) {
      getAtomsWithDisease(atom.id, botId)
        .then(json => manageOptions(json, options))
    }
    if (isCondition) {
      getAtomsWithConditions(atom.id, botId)
        .then(json => manageOptions(json, options))
    }
  }

  const manageOptions = (data, options) => {
    if (data[0].flowId || data[0].isFAQ) {
      createOptions(data, options)
    } else {
      data && data.forEach(flow => {
        createOptions(flow.atoms, options)
      });
    }
    setOptions(options)
  }

  const createOptions = (data, options) => {
    data && data.forEach(atom => {
      options.push({
        label: atom.name,
        value: atom.id,
      })
    })
    return options;
  }

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <DetectableOverflow onChange={handleOverflow}>{props.children}</DetectableOverflow>
      </components.SingleValue>
    )
  }

  const handleOverflow = isOverflowed => {
    setIsOverflowed(isOverflowed)
  }

  const handleChange = value => {
    setIsOverflowed(false)
    onChange(value)
  }

  return (
    <span data-tip={atomValue?.label} data-for={atomValue?.label}>
      <Select
        styles={customStyles}
        options={options}
        value={atomValue}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        touched={touched}
        onChange={handleChange}
        onFocus={onFocus}
        components={{ SingleValue }}
      />
      {isOverflowed && atomValue?.label && (
        <ReactTooltip
          className={classes.tooltip}
          effect="solid"
          place="top"
          type="dark"
          scrollHide={true}
          id={atomValue?.label}
        />
      )}
    </span>
  )
}

const mapStateToProps = (state: { flows: any, atom: any, activeBot: any }) => ({
  flows: state.flows,
  atom: state.atom,
  activeBot: state.activeBot
})

export default connect(mapStateToProps)(AtomSelect)
