import { BASE_URL } from '../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
  logoutOnRequestOrResponseBlob,
} from '../../../api'
import * as cookie from 'react-cookies'
import {COOKIE} from "../../../constants/cookie";

export function exportBot(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/export`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  }).then(response => logoutOnRequestOrResponseBlob(response))
}

export function uploadBot(botId, file) {
  return fetch(`${BASE_URL}/bot/${botId}/import`, {
    credentials: 'same-origin',
    headers: { Authorization: 'Bearer ' + cookie.load(COOKIE.TOKEN) },
    body: file,
    method: 'POST',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(json => {
      alertError(json.error?.message)
    })
}
