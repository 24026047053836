export enum InputTypes {
  POPUP_MESSAGE_ON_CLOSING = 'popupMessageOnClosing',
  POPUP_MESSAGE_ON_CLOSING_END_CHAT = 'popupMessageOnClosingEndChat',
  DELAY_TIME = 'delayTime',
  PORTAL_EXPIRE_MESSAGE = 'portalExpireMessage',
  CLOSE_POPUP_TEXT = 'closePopupText',
  CLOSE_POPUP_SCHEDULE_GC_TEXT = 'closePopupScheduleGCText',
  KEEP_CHATTING_BUTTON_TEXT = 'keepChattingButtonText',
  CLOSE_CHAT_BUTTON_TEXT = 'closeChatButtonText',
  SWITCH_LANGUAGE_POPUP_TEXT = 'switchLanguagePopupText',
  CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT = 'continueSwitchingLanguageButtonText',
  CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT = 'cancelSwitchingLanguageButtonText',
}

export enum InputErrorTypes {
  ERRORS_POPUP_MESSAGE_ON_CLOSING = 'errorsPopupMessageOnClosing',
  ERRORS_POPUP_MESSAGE_ON_CLOSING_END_CHAT = 'errorsPopupMessageOnClosingEndChat',
  ERRORS_DELAY_SECONDS = 'errorsDelaySeconds',
  ERRORS_PORTAL_EXPIRE_MESSAGE = 'errorsPortalExpireMessage',
  ERRORS_CLOSE_POPUP_TEXT = 'errorsClosePopupText',
  ERRORS_KEEP_CHATTING_BUTTON_TEXT = 'errorsKeepChattingButtonText',
  ERRORS_CLOSE_CHAT_BUTTON_TEXT = 'errorsCloseChatButtonText',
  ERRORS_SWITCH_LANGUAGE_POPUP_TEXT = 'errorsSwitchLanguagePopupText',
  ERRORS_CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT = 'errorsContinueSwitchingLanguageButtonText',
  ERRORS_CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT = 'errorsCancelSwitchingLanguageButtonText',
}
