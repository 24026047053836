import React, {useEffect, useState} from 'react';
import {Popover} from "@material-ui/core";

import Modal from "../../../../../uiKit/Modal";
import {ThreeIcon} from "../../../../../uiKit/icons/Icons";
import LoaderSmall from "../../../../../uiKit/loaders/loaderSmall";
import SubmitButton from "../../../../../uiKit/buttons/SubmitButton";

import {alertError} from "../../../../../api";
import {deleteConditionAPI} from "../../api/Conditions";

import ButtonPopover from "../../ButtonPopover";

import { ConditionModal } from "../ConditionModal";
import { useConditionsContext } from "../../context";

import { DUPLICATE, EDIT } from "../../../../../constants/string";

export const Flow = props => {
  const {flow, match, classes} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();
  //hot fix
  const [ isNeedOpenFlow, setIsNeedOpenFlow ] = useState(false)
  const [ showLoader, setShowLoader ] = useState(false);
  const [ openEditModal, setOpenEditModal ] = useState(false);
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
  const [ openDuplicateModal, setOpenDuplicateModal ] = useState(false);
  const { getDropdownsSettings } = useConditionsContext();

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (openEditModal || openDuplicateModal || openDeleteModal) {
      setAnchorEl(null)
    }

  }, [openDeleteModal, openEditModal, openDuplicateModal])

  useEffect(() => {
    if (props.flow.atoms && isNeedOpenFlow) {
      props.openFlow(props.flow.id)
      setIsNeedOpenFlow(false)
    }

  }, [JSON.stringify(props.flow.atoms), isNeedOpenFlow])

  function handleClick() {
    setAnchorEl(divRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const deleteCondition = () => {
    setShowLoader(true)
    deleteConditionAPI(flow.id, match.params.botId)
      .then(() => props.deleteConditionFromState(flow))
      .then(() => getDropdownsSettings())
      .then(() => setOpenDeleteModal(false))
      .then(() => setShowLoader(false))
      .catch(function () {
        alertError("Fail. Try it later");
      })
  };

  const openFlow = () => {
    if (props.flow.atoms) {
      return props.openFlow(props.flow.id);
    }

    props.getFlowCondition(props.flow.id, props.match.params.botId).then(() => {
      setIsNeedOpenFlow(true)
    })
  };

  return (
    <div>
      <div className={classes.flow}>
        <p className={classes.flowName} onClick={() => openFlow()}>{flow.flowCode}</p>
        <div
          className={`${classes.btnIcon} icon`}
          style={{cursor: 'pointer', height: 30}}
          ref={divRef}
          onClick={handleClick}>
          <ThreeIcon/>
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          className='rootElement'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
        >
          <div className={classes.popover}>
            <ButtonPopover onClick={() => setOpenDuplicateModal(true)} title='Duplicate'/>
            <ButtonPopover onClick={() => setOpenEditModal(true)} title='Edit'/>
            <ButtonPopover onClick={() => setOpenDeleteModal(true)} title='Delete'/>
          </div>
        </Popover>
      </div>
      <Modal
        open={openDuplicateModal || false}
        onClose={() => setOpenDuplicateModal(false)}
        title='Duplicate Condition'
        classes={{
          paper: classes.modalPaper,
        }}
      >
        <ConditionModal
          flow={flow}
          modalAction={DUPLICATE}
          botId={props.match.params.botId}
          onClose={() => setOpenDuplicateModal(false)}
        />
      </Modal>
      <Modal
        open={openEditModal || false}
        modalAction="edit"
        onClose={() => setOpenEditModal(false)}
        title='Edit Condition'
        classes={{
          paper: classes.modalPaper,
        }}
      >
        <ConditionModal
          flow={flow}
          modalAction={EDIT}
          botId={props.match.params.botId}
          onClose={() => setOpenEditModal(false)}
        />
      </Modal>
      <Modal
        open={openDeleteModal || false}
        onClose={() => setOpenDeleteModal(false)}
        title={"Are you sure you want to delete " + flow.flowCode + " condition?"}
      >
        {!showLoader ?
          <div className={classes.buttonsHolder}>
            <SubmitButton onClick={deleteCondition}
                          title="Delete"
            />
            <SubmitButton onClick={() => setOpenDeleteModal(false)}
                          empty
                          title="Cancel"
            />
          </div>
          : <LoaderSmall showLoader={showLoader}/>}
      </Modal>
    </div>
  )
}
