import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import  {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import DeleteAtom from '../../flowBuilder/components/DeleteAtom'



const styles = theme => ({
    atomName: {
        fontSize: '14px',
        color: theme.palette.primary.text,
        margin: 0,
        width: '90%',
        cursor: 'pointer'
    },
    deleteIcon: {
        padding: '0 10px',
        display: 'none',
        cursor: 'pointer'
    },
    atom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 0',
        borderBottom: '1px solid #0000001a',
        '&:hover>div': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    popover: {
        width: '145px',
        height: '62px'
    },
    button: {
        fontSize: '14px',
        color: '#3A3F62',
        display: 'flex',
        alignItems: 'center',
        padding: '11px 24px',
        height: '50%',
        '&:hover': {
            background: 'rgba(90, 152, 247, 0.1)'
        }
    },
    '@global': {
        '.rootElement > * ':
            {
                border: '1px solid #D7D8DF',
                boxShadow: '0px 1px 5px rgba(19, 69, 186, 0.241904)',
                borderRadius: '10px',
            },
    },
    buttonsHolder: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "24px 70px",
    },

});

const Atom = props => {
    const {atom, flowId, selectAtom, classes, activeBot} = props;

    return (
      <div>
          <div className={classes.atom}>
              <p className={classes.atomName} onClick={()=>selectAtom(atom.id)}>{atom.name}</p>
              {!atom.isHardCoded && <div className={classes.deleteIcon}>
                  <DeleteAtom
                    atom={atom}
                    activeBotId={activeBot.id}
                    selectWelcomeAtom={selectAtom}
                    flowId={flowId}
                    isConditionsFlow={true}
                    selectAtom={selectAtom}
                  />
              </div>}
          </div>
      </div>
    )
}


Atom.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    atom: PropTypes.object.isRequired,
    selectAtom: PropTypes.func.isRequired,
    activeBot: PropTypes.object
};

const mapStateToProps = state => (
    {
        activeBot: state.activeBot
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Atom)));
