import React from 'react'

import LinkTemplate from "../../flowBuilder/LinkTemplate";

import Modal from '../../../uiKit/Modal'
import { useLinkTemplateModalContext } from '../../../contexts/LinkTemplateModalProvider/LinkTemplateModalProvider'

export const LinkTemplateModal = () => {
  const { isOpen, setIsOpen, link, linkActions } = useLinkTemplateModalContext()

  const handleClose = () => setIsOpen(false)

  return (
    link && (
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={link.link.length === 0 ? `Insert link` : `Edit link`}>
        <LinkTemplate
          link={link}
          onClose={handleClose}
          editLink={linkActions.editLink}
          createLink={linkActions.createLink}
        />
      </Modal>
    )
  )
}
