export const styles = theme => ({
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white'
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: 340
  },
  searchIcon: {
    margin: 10,
    fontSize: 20,
    color: theme.palette.primary.main
  },
  atomsSearch: {
    padding: 10
  },
  activeChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5
  },
  chip: {
    margin: 5,
    border: '1px solid #EBEBEF'
  },
  mainWrap: {
    background: '#FFFFFF',
    boxShadow: 'none',
    borderRadius: 10,
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  header: {
    color: theme.palette.primary.headerText,
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Lato, sans-serif',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  addNew: {
    fontSize: 14,
    color: theme.palette.primary.main,
    border: '1px solid #1658F3',
    padding: '10px',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 40,
    margin: 5
  },
  containerFlows: {
    background: '#FFFFFF',
    marginTop: 15,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    padding: 24,
    justifyContent: 'center',
  },
  activePoint: {
    color: '#5A98F7 !important',
  },
  pointHolder: {
    marginBottom: 24,
    '& > span': {
      fontSize: 16,
      lineHeight: '19px',
      color: '#3A3F62',
      cursor: 'pointer'
    }
  },
  container: {
    background: '#F7FAFE',
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftHolder: {
    padding: '0px 26px 40px 0',
    width: '56%'
  },
  flowHolder: {
    overflow: 'visible',
    flex: 1,
    padding: '5px',
    width: '100%',
  },
  atomBuilderHolder: {
    flexBasis: '50%',
    position: 'relative'
  },
  '@global': {
    '.deseaseIcon>path:hover': {
      fill: '#fff'
    }
  },
  modalPaper: {
    overflow: "visible !important"
  }
});