import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import { CreateLinkIcon } from '../icons/Icons'
import ParamsList from './ParamsList'
import ContentEditable from './ContentEditable'
import {LinkTemplateModalContext} from "../../contexts/LinkTemplateModalProvider/LinkTemplateModalProvider";

const styles = theme => ({
  container: {
    height: 120,
    position: 'relative',
  },
  input: {
    borderTop: '1px solid #979797',
    height: 120,
    overflow: 'scroll',
    fontSize: 14,
    padding: '10px 10px 0',
    outline: 'none',
    background: '#fff',
  },
  editLink: {
    position: 'absolute',
    borderRadius: 20,
    padding: '5px 13px 13px 12px',
    height: 40,
    cursor: 'pointer',
    top: 42,
    right: -23,
    zIndex: 3,
    width: 40,
    background: '#FFFFFF',
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
  },
  '@global': {
    '.parameter': {
      background: '#deeafd',
      borderRadius: '3px',
    },
    '.linkEdited': {
      cursor: 'pointer',
      color: 'blue',
      textDecoration: 'underline',
    },
  },
})

class InputWithParamsAndLinks extends React.Component {
  static contextType = LinkTemplateModalContext

  constructor(props) {
    super(props)
    this.state = {
      text: null,
      showParams: false,
      contentSavedText: '',
      anchorEl: null,
      link: null,
    }
  }

  componentDidMount() {
    const { setLinkActions } = this.context

    setLinkActions({
      createLink: this.createLink,
      editLink: this.editLink,
      selectLink: this.selectLink,
    })

    if (this.props.value) this.updateText(this.props.value)
    this.setState({
      contentSavedText: this.props.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.link && prevState.link !== this.state.link) {
      const { setLink } = this.context
      setLink(this.state.link)
    }

    if (!prevProps.value !== !this.props.value) {
      if (this.props.value) this.updateText(this.props.value)
      this.setState({
        contentSavedText: this.props.value,
      })
    }
  }

  hideLinkIcon = () => {
    this.setState({
      showLinkIcon: false,
      linkText: '',
    })
  }

  showLinkIcon = () => {
    const selection = window.getSelection().getRangeAt(0)

    this.setState({
      showLinkIcon:
        selection.startContainer.textContent ===
        selection.endContainer.textContent,
      link: {
        link: '',
        text: window.getSelection().toString(),
        messengerExtensions: false,
      },
      open: false,
      linkText: window.getSelection().toString(),
      selection,
    })
  }

  createLink = link => {
    const { container, selection } = this.state

    selection.deleteContents()
    const aLink = document.createElement('a')
    aLink.className = 'linkEdited'
    aLink.setAttribute('tabindex', '0')
    aLink.setAttribute('data-href', `${link.link}`)
    aLink.setAttribute('contenteditable', 'false')
    aLink.id = Date.now()
    aLink.setAttribute('data-extension', `${link.messengerExtensions}`)
    aLink.innerText = link.text
    selection.insertNode(aLink)

    this.setState({
      contentSavedText: container.innerHTML,
    })
    this.updateText(container.innerHTML)
  }

  selectLink = link => {
    const data = link.dataset

    const linkObj = {
      link: data.href,
      text: link.innerHTML,
      messengerExtensions: data.extension,
    }

    this.setState(
      {
        link: linkObj,
        linkText: link.innerHTML,
        id: link.id,
      },
      this.openModal,
    )
  }

  editLink = link => {
    const { container, id } = this.state
    const currentLink = document.getElementById(id)

    currentLink.innerHTML = link.text
    currentLink.setAttribute('data-href', `${link.link}`)
    currentLink.setAttribute('data-extension', `${link.messengerExtensions}`)

    this.setState({ contentSavedText: container.innerHTML }, () => {
      this.updateText(container.innerHTML)
    })
  }

  getContainer = container => {
    this.setState({
      container,
    })
  }

  openModal = () => {
    const { setIsOpen } = this.context
    setIsOpen(true)
  }

  handleText = (e, pos) => {
    const { params } = this.props
    const event = e.nativeEvent
    let text = event.target.innerHTML

    const selection = window.getSelection().getRangeAt(0)
    const startSelection = selection.startOffset
    const textSelection = selection.startContainer.textContent

    if (
      params &&
      event.data === '{' &&
      (textSelection[startSelection - 1] &&
        textSelection[startSelection - 2]) === '{'
    ) {
      this.setState({
        showParams: true,
        pos,
        anchorEl: e.currentTarget,
        selection,
      })
    }
    if (
      event.data === '}' &&
      (textSelection[startSelection - 1] &&
        textSelection[startSelection - 2]) === '}'
    ) {
      const newTextSelection = textSelection.replace(
        /{{[^{]*?}}/g,
        '<span class="parameter" contenteditable="false">$&</span>',
      )
      text = text.replace(textSelection, newTextSelection)
    }

    this.addEventListener(e)
    this.updateText(text)
  }

  addParam(parameter) {
    const { container, selection } = this.state
    const selectedText = selection.extractContents()
    const spanParam = document.createElement('span')

    spanParam.className = 'parameter'
    spanParam.innerText = parameter
    spanParam.setAttribute('contenteditable', 'false')
    spanParam.appendChild(selectedText)
    selection.insertNode(spanParam)
    selection.startContainer.textContent =
      selection.startContainer.textContent.replace('{{', '')

    this.setState(
      {
        showParams: false,
        contentSavedText: container.innerHTML,
      },
      () => this.updateText(container.innerHTML),
    )
  }

  updateText = text => {
    this.setState(
      {
        text,
      },
      () => {
        this.props.onChange(text)
      },
    )
  }

  addEventListener = event => {
    const wrapperBlock = event.currentTarget
    wrapperBlock.addEventListener('mousemove', this.setTextContent, false)
    this.setState({ wrapperBlock })
  }

  removeEventListener = () => {
    const { wrapperBlock } = this.state
    if (wrapperBlock)
      wrapperBlock.removeEventListener('mousemove', this.setTextContent, false)
  }

  setTextContent = () => {
    const { text } = this.state
    this.setState(
      {
        contentSavedText: text,
      },
      () => {
        this.removeEventListener()
        this.props.onChange(text)
      },
    )
  }

  handelOpenModal = () => {
    this.removeEventListener()
    this.openModal()
  }

  render() {
    const { classes, params } = this.props
    const { showLinkIcon, contentSavedText, showParams, anchorEl } = this.state

    return (
      <div
        className={`${classes.container} linkHolder`}
        style={this.props.containerStyle}>
        {showLinkIcon && (
          <div className={classes.editLink} onClick={this.handelOpenModal}>
            <CreateLinkIcon />
          </div>
        )}
        <ContentEditable
          showLinkIcon={showLinkIcon}
          text={contentSavedText}
          setContainer={this.getContainer}
          openLinkIcon={this.showLinkIcon}
          hideLinkIcon={this.hideLinkIcon}
          selectLink={this.selectLink}
          handleText={(e, pos) => this.handleText(e, pos)}
          style={this.props.inputLinksContentEditableStyle}
        />
        {showParams && (
          <ParamsList
            options={params}
            show={showParams}
            anchorEl={anchorEl}
            addParam={this.addParam}
            onClose={() => this.setState({ showParams: false })}
          />
        )}
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(InputWithParamsAndLinks),
)
