import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import ValidationSelect from '../ValidationSelect/ValidationSelect'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import { usePrevious } from '../../../../hooks/usePrevious'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'
import MessageTemplate from '../MessageTemplate/MessageTemplate'

const initialTouched = {
  validation: false,
  customValidationRegex: false,
  onValidationSuccessAtomId: false,
  saveAsAttribute: false,
  onValidationFailAtomId: false,
}

const SaveUserInput = props => {
  const { classes, message, updateMessage, onDelete, saveTime } = props

  const [focus, setFocus] = useState(null)
  const [touched, setTouched] = useState(initialTouched)
  const prevSaveTime = usePrevious(saveTime)

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      const touched = {
        validation: true,
        customValidationRegex: true,
        onValidationSuccessAtomId: true,
        saveAsAttribute: true,
        onValidationFailAtomId: true,
      }

      setTouched(touched)
    }
  }, [saveTime])

  const handleChangeValidation = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.saveUserInput.validation = e.value

    if (e.value === 'CUSTOM') {
      updateTouched('customValidationRegex', false)
    }

    if (message.saveUserInput.validation === 'NONE') {
      updateTouched('onValidationFailAtomId', false)
    }

    updateMessage(messageCopy)
  }

  const handleUpdateRegex = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.saveUserInput.customValidationRegex = e.target.value

    updateMessage(messageCopy)
  }

  const handleUpdateAttribute = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.saveUserInput.saveAsAttribute = {
      id: e?.value || null,
      name: e?.label || null,
    }
    updateMessage(messageCopy)
    setFocus(false)
  }

  const handleUpdateSuccessAtomId = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.saveUserInput.onValidationSuccessAtomId = e.value

    updateMessage(messageCopy)
  }

  const handleUpdateFailAtomId = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.saveUserInput.onValidationFailAtomId = e.value

    updateMessage(messageCopy)
  }

  const updateTouched = (field, isTouched) => {
    const touchedCopy = deepCopyFunction(touched)
    touchedCopy[field] = isTouched

    setTouched(touchedCopy)
  }

  return (
    <MessageTemplate
      title={'Save user input'}
      tooltipText={
        'Set validation and save users responses to user attributes.'
      }
      onDelete={onDelete}>
      <div className={classes.body}>
        <div className={classes.section}>
          <div className={classes.inputWrap}>
            <p className={classes.label}>
              Validation <span>*</span>
            </p>
            <ValidationSelect
              value={message?.saveUserInput?.validation}
              onChange={handleChangeValidation}
              touched={touched.validation}
              onFocus={() => updateTouched('validation', true)}
            />
          </div>
          {message?.saveUserInput?.validation === 'CUSTOM' && (
            <div className={classes.inputWrap}>
              <p className={classes.label}>
                Custom regex <span>*</span>
              </p>
              <input
                className={classes.input}
                style={{
                  border:
                    touched.customValidationRegex &&
                    !message?.saveUserInput?.customValidationRegex &&
                    '1px solid red',
                }}
                placeholder="Paste custom regex here..."
                value={message?.saveUserInput?.customValidationRegex}
                onChange={handleUpdateRegex}
                onBlur={() => updateTouched('customValidationRegex', true)}
              />
            </div>
          )}
        </div>

        <div className={classes.flexContainer}>
          <div className={classes.smallInputWrap}>
            <p className={classes.label}>
              Valid input redirect <span>*</span>
            </p>
            <AtomSelect
              value={message?.saveUserInput?.onValidationSuccessAtomId}
              onChange={handleUpdateSuccessAtomId}
              touched={touched.onValidationSuccessAtomId}
              onFocus={() => updateTouched('onValidationSuccessAtomId', true)}
            />
          </div>

          <div className={classes.smallInputWrap}>
            <p className={classes.label}>Save as</p>
            <AttributeSelect
              focus={focus || !message?.saveUserInput?.saveAsAttribute?.id}
              value={message?.saveUserInput?.saveAsAttribute?.id}
              onBlur={() => setFocus(false)}
              onClick={() => setFocus(true)}
              onChange={handleUpdateAttribute}
              required={false}
              styles={{ borderRadius: 10, padding: '2px 6px' }}
              displayOptions={[DEFAULT, CHATBOT]}
              isClearable={true}
              placeholder={'Select attribute'}
              defaultAttributes={['language']}
              saveTime={saveTime}
            />
          </div>

          {message?.saveUserInput?.validation !== 'NONE' && (
            <div className={classes.smallInputWrap}>
              <p className={classes.label}>
                Invalid input redirect <span>*</span>
              </p>
              <AtomSelect
                value={message?.saveUserInput?.onValidationFailAtomId}
                onChange={handleUpdateFailAtomId}
                touched={touched.onValidationFailAtomId}
                onFocus={() => updateTouched('onValidationFailAtomId', true)}
              />
            </div>
          )}
        </div>
      </div>
    </MessageTemplate>
  )
}

SaveUserInput.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SaveUserInput)
