export const ifStringEmpty = (text) => {
    if (text == null)
        return true;
    text = text.trim();
    return (text.length === 0 && !text.trim() && text.trim() !== " ");
};

export const isObjectEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
};

export const checkLocation = url =>  window.location.href.includes(`${url}`);
