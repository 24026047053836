export const styles = () => ({
  svgAttachmentHolder: {
    cursor: 'auto',
    padding: '14px 24px',
    height: 54,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  uploadInput: {
    display: 'none',
  },
})
