import React from 'react'
import { withStyles } from '@material-ui/core'

import { styles } from './styles'
import Buttons from '../Buttons/Buttons'
import { TextMessageType } from 'models/ChatMessagesTypes'

interface Props {
  classes: any
  message: TextMessageType
  isRtl: boolean
  highlight: boolean
}

const TextMessageButton: React.FC<Props> = props => {
  const { classes, message, isRtl, highlight } = props

  return (
    <div className={classes.container} style={{ border: highlight && '1px solid #1658F3' }}>
      <span className={classes.chatBubble} style={{ direction: isRtl ? 'rtl' : 'ltr'}}>
        {message.text || message.postback}
      </span>
      {message?.buttons && message?.buttons?.length && <Buttons buttons={message?.buttons} />}
    </div>
  )
}

export default withStyles(styles)(TextMessageButton)
