import React from 'react'
import NoChartData from '../../../../uiKit/NoChartData'
import { noDataText } from '../../constants/chartsTexts'
import classes from './styles.module.scss'

const CircleChart: React.FC = () => {

  return (
    <div className={classes.container}>
      <NoChartData text={noDataText} />
    </div>
  )
}

export default CircleChart
