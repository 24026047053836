import cookie from 'react-cookies'
import moment from 'moment'

import { showSnackBar } from '../tabs/home/actions/snackBar'
import { logout } from '../tabs/authorization/actions/security'
import {COOKIE} from "../constants/cookie";

export function createJsonHeaders() {
  const token = cookie.load(COOKIE.TOKEN);
  logoutIfSessionExpired(token)
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  headers.append('Authorization', 'Bearer ' + token)
  return headers
}

export function logoutOnRequestOrResponseJson(response) {
  if (response.ok) {
    if (response.redirected) {
      return logout()
    } else {
      updateTokenIfExists(response)
      const contentType = response.headers.get('content-type')
      const isJSON = contentType && contentType.indexOf('application/json') !== -1

      return isJSON ? response.json() : response.text()
    }
  }
  if (response.url.toString().includes("/session-expired")) {
    return logout()
  }
  throw new Error('Not 2xx response')
}

export function logoutIfSessionExpired(token) {
  let inactivityLimit = cookie.load(COOKIE.INACTIVITY_LIMIT);
  if (token && inactivityLimit) {
    const timeSinceJwtIssued = getTimeSinceJwtIssued(token);
    timeSinceJwtIssued > atob(inactivityLimit) && logout()
  }
}

export function updateTokenIfExists(response) {
  const token = response.headers.get('Authorization')
  if (token) {
    cookie.save(COOKIE.TOKEN, token, { path: '/' })
  }
}

export function getTimeSinceJwtIssued(token) {
  let jwt = parseJwt(token);
  const duration = moment.duration(moment().diff(moment.unix(jwt.iat)));
  return  duration.asMilliseconds();
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function logoutOnRequestOrResponseBlob(response) {
  if (response.ok) {
    return response.redirected ? logout() : response.blob()
  }
  throw new Error('Not 2xx response')
}

export function alertSuccess(message, duration) {
  showSnackBar({
    open: true,
    text: message,
    type: 'success',
    duration,
  })
}
export const pasteCleanTextContent = (e) => {
  e.preventDefault()
  document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
}

export const paramsPattern = /{{[^{]*?}}/g
export const ifStringEmpty = (text) => {
  if (text == null)
    return true
  text = text.trim()
  return (text.length === 0 && !text.trim() && text.trim() !== ' ')
}

export function alertError(message) {
  showSnackBar({
    open: true,
    text: message,
    type: 'error',
  })
}

export function alertWarning(message) {
  showSnackBar({
    open: true,
    text: message,
    type: 'warning'
  })
}
