import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import ChatHeader from '../ChatHeader/ChatHeader'
import MessagesWrap from '../MessagesWrap'
import ChatInput from '../ChatInput/ChatInput'

import { styles } from './styles'

const ChatContainer = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <ChatHeader />
      <MessagesWrap />
      <ChatInput />
    </div>
  )
}

ChatContainer.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ChatContainer)
