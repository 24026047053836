import React, { MouseEventHandler, useContext, useMemo, useState } from 'react'
import FieldInput from '../../../../uiKit/FieldInput/FieldInput'
import classes from './styles.module.scss'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'
import {OptionMessageItemType} from '../../../../models/MessageTypes'
import { MessagesContext } from 'tabs/flowBuilder/components/MessagesWrap/MessagesWrap'

interface Props {
  field: OptionMessageItemType
  index?: number
  onChange(header: OptionMessageItemType): void
  onDelete: MouseEventHandler<HTMLSpanElement>
}

const ResponseMappingField: React.FC<Props> = ({
  field,
  index,
  onChange,
  onDelete,
}) => {
  const [focus, setFocus] = useState(null)
  const headerCopy: OptionMessageItemType = useMemo(() => ({ ...field }), [field])
  const { saveTime } = useContext(MessagesContext)

  const handleUpdateKey = value => {
    headerCopy.value = value
    onChange(headerCopy)
  }

  const handleUpdateValue = e => {
    headerCopy.key = e?.label
    onChange(headerCopy)
  }

  return (
    <div className={classes.container}>
      <AttributeSelect
        focus={focus === index || !field?.key}
        value={field?.key}
        onChange={e => handleUpdateValue(e)}
        onBlur={() => setFocus(false)}
        onClick={() => setFocus(index)}
        required={!!field.value}
        displayOptions={[DEFAULT, CHATBOT]}
        isClearable={false}
        defaultAttributes={['language']}
        placeholder={'Select attribute name'}
        saveTime={saveTime}
      />

      <FieldInput
        placeholder={'Enter JSON path'}
        onChange={handleUpdateKey}
        value={field.value}
        required={!!field.key}
      />

      <span className={classes?.iconWrap} onClick={onDelete}>
        <TrashIcon />
      </span>
    </div>
  )
}

export default ResponseMappingField
