export const styles = theme => ({
  wrapper: {
    maxHeight: '80vmin',
    overflow: "visible"
  },
  block: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '65vmin',
    padding: '30px 0 8px',
  },
  input: {
    width: '95%'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  btnAdd: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 500,
    fontSize: 14,
    padding: '0',
    margin: '8px 0 0 25px',
    cursor: 'pointer',
    color: '#1658F3'
  },
  selectWrapper: {

    "& + $selectWrapper": {
      margin: "12px 0 0",
    },
    "& > p": {
      margin: 0
    }
  }
});