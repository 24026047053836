import React from 'react'
import Dropzone from 'react-dropzone'
import { withStyles } from '@material-ui/core/styles'
import { alertError } from '../../../api'
import { handleDropWebviewImage } from '../api/webview'
import { PhotoIcon } from '../../../uiKit/icons/Icons'
import {isStringEmpty} from "../../../helpers/isStringEmpty";

const styles = theme => ({
  image: {
    width: '400px',
    height: '175px',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  replace: {
    padding: '50px 0 50px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  svgWrap: {
    width: 40,
    height: 40,
    background: 'rgba(215, 216, 223, 0.5)',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    margin: '24px auto',
    background: '#FFFFFF',
    border: '0px dashed #D7D8DF',
    boxSizing: 'border-box',
    borderRadius: 10,
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
  },
  upload: {
    color: theme.palette.primary.main,
  },
  containerEmpty: {
    margin: '24px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    border: '1px dashed #D7D8DF',
    borderRadius: 10,
  },
  description: {
    margin: '8px 0 0',
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.text.gray,
  },
  dropzone: {
    border: 0,
    width: '100%',
    cursor: 'pointer',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
})

class DropzoneWebview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  handleDrop = files => {
    if (files[0].size > 1048576) {
      alertError('File size should be less then 10 MB.', 10000)
    } else {
      const file = new FormData()
      file.append('file', files[0])
      handleDropWebviewImage(file).then(json => {
        this.props.onChange(json.url)
      })
    }
  }

  render() {
    const { classes, value } = this.props
    const { show } = this.state

    return (
      <div className={classes.centeredContainer}>
        <div
          className={
            !isStringEmpty(value) ? classes.container : classes.containerEmpty
          }>
          <Dropzone onDrop={this.handleDrop} className={classes.dropzone}>
            {value && !show && (
              <div
                style={{
                  background: `url("${value}") no-repeat center center`,
                  backgroundSize: 'contain',
                  height: 200,
                  width: '100%',
                }}
              />
            )}
            {isStringEmpty(value) && (
              <div className={classes.replace}>
                <PhotoIcon height="19px" width="24px" />
                <p className={classes.description}>
                  Choose or drop some image here
                </p>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(DropzoneWebview)
