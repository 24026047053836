import React, {useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Heading from '../../../../uiKit/texts/Heading'
import Input from '../../../../uiKit/inputs/Input'
import LaguageSelector from '../../../../uiKit/LaguageSelector/LaguageSelector'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import Checkbox from '../../../../uiKit/Checkbox'
import RadioButtonGroup from '../../../../uiKit/RadioButtonGroup'
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core'
import {deepCopyFunction} from '../../../../helpers/deepCopyFunction'
import {updateWidget} from '../../api/widget'
import {deepCompare} from '../../../../helpers/deepCompare'
import {WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'


import classes from './styles.module.scss'
import {SPUN_PEARL_GREY} from '../../../../constants/colors'
import {setErrors} from '../../../../helpers/setErrors/setErrors'
import {InputErrorTypes, InputTypes} from './constants'

interface Props {
  match: any
  widgetSettings: WidgetSettingsType
}

const NateraSettingsTab: React.FC<Props> = ({match, widgetSettings}) => {
  const nateraLanguageConfigs = useMemo(() => widgetSettings?.nateraLanguageConfigs, [widgetSettings?.nateraLanguageConfigs])
  const [selectedLanguage, setSelectedLanguages] = useState({label: 'English', value: 'en'})
  const showPopupMessageOnClosing = nateraLanguageConfigs[selectedLanguage.value]?.showPopupMessageOnClosing
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  const [inputFields, setInputFields] = useState({
    popupMessageOnClosing: widgetSettings?.nateraLanguageConfigs[selectedLanguage.value]?.popupMessageOnClosing || '',
    popupMessageOnClosingEndChat: widgetSettings?.nateraLanguageConfigs[selectedLanguage.value]?.popupMessageOnClosingEndChat || '',
    delayTime: nateraLanguageConfigs[selectedLanguage.value]?.widgetPopupDelayOnClosing,
    portalExpireMessage: nateraLanguageConfigs[selectedLanguage.value]?.portalExpireMessage || '',
    closePopupText: nateraLanguageConfigs[selectedLanguage.value]?.closePopupText || '',
    closePopupScheduleGCText: nateraLanguageConfigs[selectedLanguage.value]?.closePopupScheduleGCText || '',
    keepChattingButtonText: nateraLanguageConfigs[selectedLanguage.value]?.keepChattingButtonText || '',
    closeChatButtonText: nateraLanguageConfigs[selectedLanguage.value]?.closeChatButtonText || '',
    switchLanguagePopupText: nateraLanguageConfigs[selectedLanguage.value]?.switchLanguagePopupText || '',
    continueSwitchingLanguageButtonText: nateraLanguageConfigs[selectedLanguage.value]?.continueSwitchingLanguageButtonText || '',
    cancelSwitchingLanguageButtonText: nateraLanguageConfigs[selectedLanguage.value]?.cancelSwitchingLanguageButtonText || '',
  })

  const [inputErrorMessages, setInputErrorMessages] = useState({
    errorsPopupMessageOnClosing: [],
    errorsPopupMessageOnClosingEndChat: [],
    errorsDelaySeconds: [],
    errorsPortalExpireMessage: [],
    errorsClosePopupText: [],
    errorsKeepChattingButtonText: [],
    errorsCloseChatButtonText: [],
    errorsSwitchLanguagePopupText: [],
    errorsContinueSwitchingLanguageButtonText: [],
    errorsCancelSwitchingLanguageButtonText: [],
  })

  const quickReplyPositions = [
    {value: 'HORIZONTAL', label: 'Horizontal view'},
    {value: 'VERTICAL', label: 'Vertical view'},
  ]

  const handleUpdateWidgetSettings = (value: string | boolean | number, field: string) => {
    widgetCopy.nateraLanguageConfigs[selectedLanguage.value][field] = value

    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleChangeLanguage = (language) => {
    setSelectedLanguages(language)

    setInputFields({
      ...inputFields,
      [InputTypes.POPUP_MESSAGE_ON_CLOSING]: nateraLanguageConfigs[language.value]?.popupMessageOnClosing || '',
      [InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT]: nateraLanguageConfigs[language.value]?.popupMessageOnClosingEndChat || '',
      [InputTypes.DELAY_TIME]: nateraLanguageConfigs[language.value]?.widgetPopupDelayOnClosing,
      [InputTypes.PORTAL_EXPIRE_MESSAGE]: nateraLanguageConfigs[language.value]?.portalExpireMessage || '',
      [InputTypes.CLOSE_POPUP_TEXT]: nateraLanguageConfigs[language.value]?.closePopupText || '',
      [InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT]: nateraLanguageConfigs[language.value]?.closePopupScheduleGCText || '',
      [InputTypes.KEEP_CHATTING_BUTTON_TEXT]: nateraLanguageConfigs[language.value]?.keepChattingButtonText || '',
      [InputTypes.CLOSE_CHAT_BUTTON_TEXT]: nateraLanguageConfigs[language.value]?.closeChatButtonText || '',
      [InputTypes.SWITCH_LANGUAGE_POPUP_TEXT]: nateraLanguageConfigs[language.value]?.switchLanguagePopupText || '',
      [InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT]: nateraLanguageConfigs[language.value]?.continueSwitchingLanguageButtonText || '',
      [InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT]: nateraLanguageConfigs[language.value]?.cancelSwitchingLanguageButtonText || ''
    })
  }

  const onChangeQuickReplyPosition = (event) => {
    handleUpdateWidgetSettings(event.target.value, 'quickReplyPosition')
  }

  const handleUpdateUserInputWithoutValidation = (message, inputType) => {
    handleUpdateWidgetSettings(message, inputType)
  }

  const validateUserInput = (message, inputErrorType) => {
    const errors = setErrors(message)
    setInputErrorMessages({
      ...inputErrorMessages,
      [inputErrorType]: errors
    })

    return !errors.length
  }

  const setInputChangeMessage = (key, value) => {
    setInputFields({
      ...inputFields,
      [key]: value
    })
  }

  const handleUpdateUserInputWithValidation = (message, inputErrorType, inputType) => {
    const isValid = validateUserInput(message, inputErrorType)
    isValid && handleUpdateWidgetSettings(message, inputType)
  }

  const handleChangeDelayTime = (value, isCustom) => {
    setInputFields({
      ...inputFields,
      [InputTypes.DELAY_TIME]: value
    })

    if (!isCustom) {
      handleUpdateWidgetSettings(value, 'widgetPopupDelayOnClosing')
    }
  }

  return (
    <div>
      <div className={classes.header}>
        <Heading>Natera Settings</Heading>
        <LaguageSelector onChange={handleChangeLanguage} value={selectedLanguage}/>
      </div>

      <Paragraph style={{color: SPUN_PEARL_GREY}}>
        Add and customize widget settings
      </Paragraph>

      <div className={classes.body}>
        <div className={classes.form}>
          <Paragraph style={{color: 'black'}}>Quick replies</Paragraph>
          <RadioGroup
            row
            value={widgetSettings?.nateraLanguageConfigs[selectedLanguage.value]?.quickReplyPosition}
            onChange={onChangeQuickReplyPosition}
          >
            {quickReplyPositions.map(position => (
              <FormControlLabel
                key={position.value}
                value={position.value}
                label={position.label}
                control={
                  <Radio
                    color="primary"
                  />
                }
              />
            ))}
          </RadioGroup>
          <Checkbox
            label="Show pop-up messages in mobile"
            checked={widgetSettings?.nateraLanguageConfigs[selectedLanguage.value]?.showPopupMessagesInMobile}
            onChange={(value: boolean) => handleUpdateWidgetSettings(value, 'showPopupMessagesInMobile')}
          />
          <Checkbox
            label="Show pop-up message after closing the widget"
            checked={showPopupMessageOnClosing}
            onChange={(value: boolean) => handleUpdateWidgetSettings(value, 'showPopupMessageOnClosing')}
          />
          {showPopupMessageOnClosing &&
            <>
              <Input
                title="Pop-up message after closing the widget"
                placeholder="Enter text to display the popup message"
                value={inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING]}
                onChange={(e) => setInputChangeMessage(InputTypes.POPUP_MESSAGE_ON_CLOSING, e.target.value)}
                handleEnterPress={() =>
                  handleUpdateUserInputWithValidation(
                    inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING],
                    InputErrorTypes.ERRORS_POPUP_MESSAGE_ON_CLOSING,
                    InputTypes.POPUP_MESSAGE_ON_CLOSING
                  )
                }
                onBlur={() =>
                  handleUpdateUserInputWithValidation(
                    inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING],
                    InputErrorTypes.ERRORS_POPUP_MESSAGE_ON_CLOSING,
                    InputTypes.POPUP_MESSAGE_ON_CLOSING
                  )
                }
                error={!!inputErrorMessages.errorsPopupMessageOnClosing.length}
                errorMessage={inputErrorMessages.errorsPopupMessageOnClosing[0]}
              />
              <Input
                title="End chat pop-up message after closing the widget"
                placeholder="Enter text to display the end chat popup message"
                value={inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT]}
                onChange={(e) => setInputChangeMessage(InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT, e.target.value)}
                handleEnterPress={() =>
                  handleUpdateUserInputWithValidation(
                    inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT],
                    InputErrorTypes.ERRORS_POPUP_MESSAGE_ON_CLOSING_END_CHAT,
                    InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT
                  )
                }
                onBlur={() =>
                  handleUpdateUserInputWithValidation(
                    inputFields[InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT],
                    InputErrorTypes.ERRORS_POPUP_MESSAGE_ON_CLOSING_END_CHAT,
                    InputTypes.POPUP_MESSAGE_ON_CLOSING_END_CHAT
                  )
                }
                error={!!inputErrorMessages.errorsPopupMessageOnClosingEndChat.length}
                errorMessage={inputErrorMessages.errorsPopupMessageOnClosingEndChat[0]}
              />
              <RadioButtonGroup
                title="Delay time"
                value={inputFields[InputTypes.DELAY_TIME]}
                onChange={(value, isCustom) => handleChangeDelayTime(value, isCustom)}
                onBlur={() =>
                  handleUpdateUserInputWithValidation(
                    inputFields[InputTypes.DELAY_TIME],
                    InputErrorTypes.ERRORS_DELAY_SECONDS,
                    InputTypes.DELAY_TIME
                  )
                }
                errors={inputErrorMessages.errorsDelaySeconds}
              />
            </>
          }
          <Input
            title="Portal expire message"
            placeholder="Enter text to display the portal expire message"
            value={inputFields[InputTypes.PORTAL_EXPIRE_MESSAGE]}
            onChange={(e) => setInputChangeMessage(InputTypes.PORTAL_EXPIRE_MESSAGE, e.target.value)}
            handleEnterPress={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.PORTAL_EXPIRE_MESSAGE],
                InputErrorTypes.ERRORS_PORTAL_EXPIRE_MESSAGE,
                InputTypes.PORTAL_EXPIRE_MESSAGE
              )
            }
            onBlur={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.PORTAL_EXPIRE_MESSAGE],
                InputErrorTypes.ERRORS_PORTAL_EXPIRE_MESSAGE,
                InputTypes.PORTAL_EXPIRE_MESSAGE
              )
            }
            error={!!inputErrorMessages.errorsPortalExpireMessage.length}
            errorMessage={inputErrorMessages.errorsPortalExpireMessage[0]}
          />
          <Input
              title="Close popup text"
              placeholder="Enter text to display close popup text"
              value={inputFields[InputTypes.CLOSE_POPUP_TEXT]}
              onChange={(e) => setInputChangeMessage(InputTypes.CLOSE_POPUP_TEXT, e.target.value)}
              handleEnterPress={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.CLOSE_POPUP_TEXT],
                  InputErrorTypes.ERRORS_CLOSE_POPUP_TEXT,
                  InputTypes.CLOSE_POPUP_TEXT
                )
              }
              onBlur={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.CLOSE_POPUP_TEXT],
                  InputErrorTypes.ERRORS_CLOSE_POPUP_TEXT,
                  InputTypes.CLOSE_POPUP_TEXT
                )
              }
              error={!!inputErrorMessages.errorsClosePopupText.length}
              errorMessage={inputErrorMessages.errorsClosePopupText[0]}
          />
          <Input
              title="Close popup schedule GC text"
              placeholder="Enter text to display close popup schedule GC text"
              value={inputFields[InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT]}
              onChange={(e) => setInputChangeMessage(InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT, e.target.value)}
              handleEnterPress={() =>
                handleUpdateUserInputWithoutValidation(
                  inputFields[InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT],
                  InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT
                )
              }
              onBlur={() =>
                handleUpdateUserInputWithoutValidation(
                  inputFields[InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT],
                  InputTypes.CLOSE_POPUP_SCHEDULE_GC_TEXT
                )
              }
          />
          <Input
              title="Keep chatting button text"
              placeholder="Enter text to display keep chatting button text"
              value={inputFields[InputTypes.KEEP_CHATTING_BUTTON_TEXT]}
              onChange={(e) => setInputChangeMessage(InputTypes.KEEP_CHATTING_BUTTON_TEXT, e.target.value)}
              handleEnterPress={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.KEEP_CHATTING_BUTTON_TEXT],
                  InputErrorTypes.ERRORS_KEEP_CHATTING_BUTTON_TEXT,
                  InputTypes.KEEP_CHATTING_BUTTON_TEXT
                )
              }
              onBlur={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.KEEP_CHATTING_BUTTON_TEXT],
                  InputErrorTypes.ERRORS_KEEP_CHATTING_BUTTON_TEXT,
                  InputTypes.KEEP_CHATTING_BUTTON_TEXT
                )
              }
              error={!!inputErrorMessages.errorsKeepChattingButtonText.length}
              errorMessage={inputErrorMessages.errorsKeepChattingButtonText[0]}
          />
          <Input
              title="Close chat button text"
              placeholder="Enter text to display close chat button text"
              value={inputFields[InputTypes.CLOSE_CHAT_BUTTON_TEXT]}
              onChange={(e) => setInputChangeMessage(InputTypes.CLOSE_CHAT_BUTTON_TEXT, e.target.value)}
              handleEnterPress={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.CLOSE_CHAT_BUTTON_TEXT],
                  InputErrorTypes.ERRORS_CLOSE_CHAT_BUTTON_TEXT,
                  InputTypes.CLOSE_CHAT_BUTTON_TEXT
                )
              }
              onBlur={() =>
                handleUpdateUserInputWithValidation(
                  inputFields[InputTypes.CLOSE_CHAT_BUTTON_TEXT],
                  InputErrorTypes.ERRORS_CLOSE_CHAT_BUTTON_TEXT,
                  InputTypes.CLOSE_CHAT_BUTTON_TEXT
                )
              }
              error={!!inputErrorMessages.errorsCloseChatButtonText.length}
              errorMessage={inputErrorMessages.errorsCloseChatButtonText[0]}
          />
          <Input
            title="Switch language popup text"
            placeholder="Enter text to display"
            value={inputFields[InputTypes.SWITCH_LANGUAGE_POPUP_TEXT]}
            onChange={(e) => setInputChangeMessage(InputTypes.SWITCH_LANGUAGE_POPUP_TEXT, e.target.value)}
            handleEnterPress={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.SWITCH_LANGUAGE_POPUP_TEXT],
                InputErrorTypes.ERRORS_SWITCH_LANGUAGE_POPUP_TEXT,
                InputTypes.SWITCH_LANGUAGE_POPUP_TEXT
              )
            }
            onBlur={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.SWITCH_LANGUAGE_POPUP_TEXT],
                InputErrorTypes.ERRORS_SWITCH_LANGUAGE_POPUP_TEXT,
                InputTypes.SWITCH_LANGUAGE_POPUP_TEXT
              )
            }
            error={!!inputErrorMessages.errorsSwitchLanguagePopupText.length}
            errorMessage={inputErrorMessages.errorsSwitchLanguagePopupText[0]}
          />
          <Input
            title="Switch language Continue button text (primary)"
            placeholder="Enter text to display"
            value={inputFields[InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT]}
            onChange={(e) => setInputChangeMessage(InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT, e.target.value)}
            handleEnterPress={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT],
                InputErrorTypes.ERRORS_CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT,
                InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT
              )
            }
            onBlur={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT],
                InputErrorTypes.ERRORS_CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT,
                InputTypes.CONTINUE_SWITCHING_LANGUAGE_BUTTON_TEXT
              )
            }
            error={!!inputErrorMessages.errorsContinueSwitchingLanguageButtonText.length}
            errorMessage={inputErrorMessages.errorsContinueSwitchingLanguageButtonText[0]}
          />
          <Input
            title="Switch language Cancel button text (secondary)"
            placeholder="Enter text to display"
            value={inputFields[InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT]}
            onChange={(e) => setInputChangeMessage(InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT, e.target.value)}
            handleEnterPress={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT],
                InputErrorTypes.ERRORS_CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT,
                InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT
              )
            }
            onBlur={() =>
              handleUpdateUserInputWithValidation(
                inputFields[InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT],
                InputErrorTypes.ERRORS_CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT,
                InputTypes.CANCEL_SWITCHING_LANGUAGE_BUTTON_TEXT
              )
            }
            error={!!inputErrorMessages.errorsCancelSwitchingLanguageButtonText.length}
            errorMessage={inputErrorMessages.errorsCancelSwitchingLanguageButtonText[0]}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(NateraSettingsTab))
