import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import Modal from "../../../../uiKit/Modal";
import Headline from '../../../../uiKit/texts/Headline';
import {SearchIcon} from '../../../../uiKit/icons/Icons.js';

//import {getParams} from '../../../../api/Bots';

import {HOME_PATH} from "../../../../configs";
import {ifStringEmpty} from "../../../../static";

import AtomEditor from '../../../flowBuilder/components/AtomEditor';

import Atom from "../Atom";
import AddNewAtom from "../AddNewAtom";

import { Flow } from "./Flow";
import { FlowHeader } from "./FlowHeader";
import { ConditionModal } from "./ConditionModal";

import { ADD } from "../../../../constants/string";

export class ConditionsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFlow: '',
      redirectId: null,
      currentFlow: null,
      showFaq: false,
      searchText: '',
      openConditionModal: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.currentFlow) {
      let updatedConditions = props.conditions.find(flow => flow.id === state.currentFlow.id);
      return ({
        currentFlow: updatedConditions
      });
    }

    return null;
  }

  componentDidMount() {
 //   getParams();
    this.props.getConditions();
  }

  selectAtom(id) {
    this.setState({
      redirectId: id,
    })
  }

  renderRedirect = () => {
    const {redirectId, currentFlow} = this.state;
    const {botId} = this.props.match.params;
    const href = window.location.href;

    // close atom builder component after leaving current condition
    if(!currentFlow && redirectId){
      this.setState({
         redirectId: null
      })
    }

    if ((redirectId === '' || !currentFlow) && !href.endsWith('/conditions')) {
      return <Redirect to={`${HOME_PATH}/bot/${botId}/conditions`}/>
    }

    if (redirectId === null || currentFlow === null) return;

    if (!href.includes(`/${redirectId}`)) {
      return <Redirect to={`${HOME_PATH}/bot/${botId}/conditions/${redirectId}`}/>
    }
  };

  atomFilter = (atom) => {
    const searchText = this.state.searchText.toUpperCase();
    if (ifStringEmpty(searchText)) return true;
    if (atom.name.toUpperCase().includes(searchText)) return true;
    if (!atom.messages) return false;
    return atom.messages.some(message => {
      if (message.text && message.text.toUpperCase().includes(searchText))
        return true;
      if (message.items && message.items.some(item => item.text && item.text.toUpperCase().includes(searchText)))
        return true;
      return (message.attachment && message.attachment.payload.elements && message.attachment.payload.elements.some(element =>
        element.subtitle && element.subtitle.toUpperCase().includes(searchText)
        || element.title && element.title.toUpperCase().includes(searchText)));
    })
  };

  backToFlows = () => {
    this.setState({
      currentFlow: null,
      showFaq: false
    })
  };

  openFlow = (id) => {
    let currentFlow = this.props.conditions.find(flow => flow.id === id);
    this.setState({
      currentFlow
    })
  };

  openConditionModal = () => {
    this.setState({openConditionModal: true})
  };
  closeConditionModal = () => {
    this.setState({openConditionModal: false})
  };

  onChangeSwitch = () => {
    this.setState({
      showFaq: !this.state.showFaq
    })
  };

  render() {
    const {classes, conditions, getFlowCondition, updateConditionAtom} = this.props;

    const {showFaq, currentFlow, searchText, openConditionModal} = this.state;

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.leftHolder}>
            <div className={classes.header} style={!currentFlow ? {justifyContent: 'flex-start'} : {}}>
              <Headline
                title="Conditions"
              />
              {currentFlow && <div className={classes.inputHolder}>
                <input
                  className={classes.input}
                  value={searchText}
                  placeholder="Search"
                  onChange={event => this.setState({searchText: event.target.value})}
                />
                <SearchIcon width="20" height="20" color="#D8D8D8"/>
              </div>}
              {!currentFlow &&
              <div
                onClick={this.openConditionModal}
                className={classes.addNew} style={{
                  margin: '50px 20px 40px',
                  cursor: 'pointer',
                  padding: '14px 16px'
                }}
              >
                + Add
              </div>}
            </div>
            <div className={classes.flowHolder}>
              {!currentFlow && conditions != null && <div className={classes.containerFlows}>
                <FlowHeader currentFlow={currentFlow}/>
                {conditions.slice()
                  .reverse()
                  .map((flow) => (
                    <Flow
                      flow={flow}
                      key={flow.id}
                      getFlowCondition={getFlowCondition}
                      openFlow={(id) => this.openFlow(id)}
                      deleteConditionFromState={this.props.deleteConditionFromState}
                    />
                  ))
                }
              </div>}
              {currentFlow && (
                <div className={classes.containerFlows}>
                  <FlowHeader
                    valueSwitch={showFaq}
                    currentFlow={currentFlow}
                    backToFlows={this.backToFlows}
                    onChangeSwitch={this.onChangeSwitch}
                  />
                  <AddNewAtom
                      isConditionAtom
                      showFaq={showFaq}
                      flowId={currentFlow.id}
                  />
                  {conditions.find(flow => flow.id === currentFlow.id).atoms
                    ?.filter((atom) => this.atomFilter(atom))
                    .reverse()
                    .map((atom) => (
                      <Atom
                        flowId={currentFlow.id}
                        selectAtom={(id) => this.selectAtom(id)} atom={atom}
                        key={atom.id}
                      />
                    ))
                  }
                </div>
              )}
            </div>
          </div>
          <Modal
            open={openConditionModal || false}
            onClose={() => this.closeConditionModal()}
            title='Add condition'
            classes={{
              paper: classes.modalPaper,
            }}
          >

            <ConditionModal
              edit={true}
              modalAction={ADD}
              botId={this.props.match.params.botId}
              onClose={this.closeConditionModal}
            />
          </Modal>
          {currentFlow && (
            <div className={classes.atomBuilderHolder}>
              <Route
                path={`${HOME_PATH}/bot/:botId/conditions/:atomId`}
                render={() => (
                  <AtomEditor
                    updateConditionAtom={updateConditionAtom}
                    flowId={currentFlow ? currentFlow.id : null}
                  />
                )}/>
            </div>
          )}
          {this.renderRedirect()}
        </div>
      </div>
    );
  }
}
