/**
 * Created by Admin on 22.03.2018.
 */
/**
 * @author Den Boyko
 * @version 1.0
 */
import React from 'react'
import Dropzone from 'react-dropzone'
import { withStyles } from '@material-ui/core/styles'
import { alertError, logoutOnRequestOrResponseJson } from '../../api'
import { BASE_URL } from '../../configs'
import { PhotoIcon } from '../icons/Icons'
import * as cookie from 'react-cookies'
import { isStringEmpty } from '../../helpers/isStringEmpty'
import {COOKIE} from "../../constants/cookie";

const styles = theme => ({
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  replace: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: 80,
  },
  container: {
    background: '#FFFFFF',
    border: '1px dashed #D7D8DF',
    boxSizing: 'border-box',
    borderRadius: 10,
    margin: '0 auto',
    width: '60%',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'center',
    padding: '60px 0 40px',
  },
  containerEmpty: {
    padding: '47px 0 25px',
    display: 'flex',
    justifyContent: 'center',
  },
})

class FileDropzone extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }
    this.handleDrop = this.handleDrop.bind(this)
  }

  handleDrop(files) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const file = new FormData()
    file.append('file', files[0])
    fetch(BASE_URL + '/image', {
      credentials: 'same-origin',
      method: 'POST',
      headers: { Authorization: 'Bearer ' + cookie.load(COOKIE.TOKEN) },
      body: file,
    })
      .then(response => logoutOnRequestOrResponseJson(response))
      .then(response => {
        self.props.onChange(response.url)
      })
      .catch(function(error) {
        alertError('Fail. Try it later', 10000)
      })
  }

  render() {
    const { classes } = this.props
    return (
      <div
        className={
          isStringEmpty(this.props.value)
            ? classes.container
            : classes.containerEmpty
        }>
        <Dropzone
          onDrop={this.handleDrop}
          style={{
            width: !isStringEmpty(this.props.value) ? '100px' : '60%',
            height: !isStringEmpty(this.props.value) ? '100px' : 70,
            margin: '0 auto',
            border: !isStringEmpty(this.props.value)
              ? '1px solid #e5e5e5'
              : '0',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}>
          <img
            src={this.props.value}
            alt="file"
            className={classes.image}
            style={
              this.props.value && !this.state.show ? {} : { display: 'none' }
            }
          />
          {isStringEmpty(this.props.value) && (
            <div className={classes.replace} style={{}}>
              <PhotoIcon
                style={{ marginBottom: 5 }}
                height="15px"
                width="19px"
              />
              {/*<InsertPhoto/>*/}
              <p
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  color: '#616581',
                  maxWidth: 200,
                  margin: 0,
                }}>
                {this.props.title}
              </p>
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FileDropzone)
