import React from 'react'

import { useContextValue } from './useContextValue'
import { LinkTemplateModal } from '../../tabs/webview/components/LinkTemplateModal'

export const LinkTemplateModalContext = React.createContext({})

export const LinkTemplateModalProvider = props => {
  const { value } = useContextValue()

  return (
    <LinkTemplateModalContext.Provider value={value}>
      {props.children}
      <LinkTemplateModal />
    </LinkTemplateModalContext.Provider>
  )
}

export const useLinkTemplateModalContext = () => {
  const context = React.useContext(LinkTemplateModalContext)

  if (!context) {
    throw new Error(
      'useLinkTemplateModalContext must be inside LinkTemplateModalProvider',
    )
  }

  return context
}
