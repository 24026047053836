import { useState, useMemo } from 'react'

export const useContextValue = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState(null)
  const [linkActions, setLinkActions] = useState({})

  const value = useMemo(
    () => ({
      link,
      isOpen,
      setLink,
      setIsOpen,
      linkActions,
      setLinkActions,
    }),
    [isOpen, link, linkActions],
  )

  return {
    value,
  }
}
