import { BASE_URL } from '../../../configs'

import { saveAccountSettings } from '../actions/account'
import {
  createJsonHeaders,
  alertError,
  alertSuccess,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import * as cookie from 'react-cookies'
import {COOKIE} from "../../../constants/cookie";

export const getAccountSettings = adminId => {
  return fetch(`${BASE_URL}/admin/settings/${adminId}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: createJsonHeaders(),
  })
    .then(response => {
      if (!response.ok) {
        alertError(
          'Sorry but something going wrong at getting intents please ping support!',
        )
        return
      }
      return response
    })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => saveAccountSettings(response))
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export const updateAccountSettings = (adminId, body) => {
  return fetch(`${BASE_URL}/admin/settings/${adminId}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        alertError(
          'Sorry but something going wrong at getting intents please ping support!',
        )
        return
      }
      alertSuccess("User's settings has been successfully updated.", 3000)

      return response
    })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => saveAccountSettings(response))
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export const dropImage = files => {
  const file = new FormData()
  file.append('file', files[0])
  return fetch(BASE_URL + '/file', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { Authorization: 'Bearer ' + cookie.load(COOKIE.TOKEN) },
    body: file,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(function(error) {
      alertError('Fail. Try it later', 10000)
    })
}
