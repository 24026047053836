import * as cookie from 'react-cookies'
import { store } from '../../../index'
import {COOKIE} from "../../../constants/cookie";

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const IS_NOT_AUTHENTICATED = 'IS_NOT_AUTHENTICATED'

export const authenticate = token =>
  store.dispatch({
    type: IS_AUTHENTICATED,
    security: {
      token: token,
      authenticated: true,
    },
  })

export const logout = () => {
  cookie.remove(COOKIE.TOKEN, { path: '/' })
  cookie.remove(COOKIE.INACTIVITY_LIMIT, { path: '/' })
  store.dispatch({
    type: IS_NOT_AUTHENTICATED,
    security: {
      token: null,
      authenticated: false,
    },
  })
}
