import React from 'react'
import Headline from '../texts/Headline'
import DateControl from '../DateControl'

import classes from './styles.module.scss'

interface Props {
  title: string
  botId: number
  loadData: any
  setFromDateCallback?: any
  setToDateCallback?: any
}

const HeadlineWrapWithDateControl: React.FC<Props> = ({
  botId,
  loadData,
  title,
  setFromDateCallback,
  setToDateCallback,
}) => (
  <div className={classes.container}>
    <Headline title={title} />
    <DateControl
      className={classes.DateControl}
      botId={botId}
      loadData={loadData}
      custom={false}
      setFromDateCallback={setFromDateCallback}
      setToDateCallback={setToDateCallback}
    />
  </div>
)

export default HeadlineWrapWithDateControl