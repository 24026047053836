import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";

import { styles } from "./styles";
import { ConditionModal as View } from "./ConditionModal";

const mapStateToProps = state => (
  {
    diseases: state.diseases,
    ethnicBackground: state.ethnicBackground
  });

export const ConditionModal = withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(View)));
