import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'
import * as cookie from 'react-cookies'
import { BACKEND_URL } from '../configs'

class WebSocketClient {
  _client

  _messageSubscription
  _chatStatusSubscription

  _receiveMessageCallback
  _receiveChatStatusCallback

  connect(botId) {
    this._createNewSocketConnection()
    this._client.connect({}, () => this._onConnectionSuccess(botId))
  }

  _createNewSocketConnection() {
    this._client = Stomp.over(this._createSockJs)
    this._client.reconnect_delay = 5000
    this._client.heartbeat.incoming = 10000
    this._client.heartbeat.outgoing = 10000
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._client.debug = () => {} //NOSONAR
  }

  _createSockJs = () => {
    return new SockJS(`${BACKEND_URL}/web?Authorization=${cookie.load('YWxpYWFkbWlu')}`)
  }

  _onConnectionSuccess(botId) {
    if (this._client.connected) {
      this._subscribeForTopics(botId)
    }
  }

  _subscribeForTopics(botId) {
    this._messageSubscription = this._client.subscribe(`/topic/admin.message.${botId}`, message =>
      this._receiveMessageCallback(message),
    )

    this._chatStatusSubscription = this._client.subscribe(`/topic/admin.chatStatus.${botId}`, chatStatus =>
      this._receiveChatStatusCallback(chatStatus),
    )
  }

  configureMessageTopic(receiveMessageCallback) {
    this._receiveMessageCallback = receiveMessageCallback
  }

  configureChatStatusTopic(receiveChatStatusCallback) {
    this._receiveChatStatusCallback = receiveChatStatusCallback
  }

  sendMessage(message) {
    if (this._client?.connected) {
      this._client.send('/app/admin', {}, JSON.stringify(message))
    }
  }

  sendStatus(status, body, botId) {
    if (body.nativeMessage) {
      const nativeMessage = JSON.parse(body.nativeMessage)

      if (nativeMessage.message) {
        const statusObj = {
          status: {
            messageId: nativeMessage.message.id,
            status,
          },
          chatId: nativeMessage.chatId,
          websiteLocation: nativeMessage.websiteLocation,
        }

        if (!nativeMessage.isFromBot && nativeMessage?.chatId) {
          this._client.send(`/app/widget/${botId}/${nativeMessage.chatId}`, {}, JSON.stringify(statusObj))
        }
      }
    }
  }

  disconnect() {
    if (this._client?.connected) {
      this._messageSubscription.unsubscribe()
      this._chatStatusSubscription.unsubscribe()
      this._client.disconnect()
    }
  }
}

export default WebSocketClient
