import { BASE_URL } from '../../../configs'
import * as cookie from 'react-cookies'
import { addWebview, updateWebviews, findAndUpdateWebview } from '../actions'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import {COOKIE} from "../../../constants/cookie";

export function handleDropWebviewImage(file) {
  return fetch(`${BASE_URL}/webview/file`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { Authorization: 'Bearer ' + cookie.load(COOKIE.TOKEN) },
    body: file,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      return json
      alertSuccess('Widget settings updated', 3000)
    })
    .catch(error => {
      alertError('Fail. Try it later', 10000)
    })
}

export function createWebview(webview, language) {
  return fetch(`${BASE_URL}/webview?language=${language}`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: createJsonHeaders(),
    body: JSON.stringify(webview),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      addWebview(json)
      alertSuccess('Webview is created succesfully', 3000)
    })
}

export function deleteWebview(id, botId) {
  return fetch(`${BASE_URL}/webview/${id}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: createJsonHeaders(),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(() => {
      getWebviews(botId)
      alertSuccess('Webview is deleted succesfully', 3000)
    })
    .catch(error => {
      alertError('Fail. Try it later', 10000)
    })
}

export function getWebviews(botId, language) {
  return fetch(`${BASE_URL}/webview/bot/${botId}/all?language=${language}`, {
    credentials: 'same-origin',
    method: 'GET',
    headers: createJsonHeaders(),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      updateWebviews(json)
    })
    .catch(() => {
      alertError('Fail. Try it later', 10000)
    })
}

export function updateWebview(webview, language) {
  return fetch(`${BASE_URL}/webview?language=${language}`, {
    credentials: 'same-origin',
    method: 'PUT',
    headers: createJsonHeaders(),
    body: JSON.stringify(webview),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      findAndUpdateWebview(json)
    })
    .catch(() => {
      alertError('Fail. Try it later', 10000)
    })
}
