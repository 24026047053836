import React, { ReactElement } from 'react'
import { withStyles } from '@material-ui/core'

import { styles } from './styles'
import { DELIVERED, READ } from 'constants/statuses'
import { DeliveredStatusIcon, ReadStatusIcon, SentStatusIcon } from 'uiKit/icons/StatusIcons.ts'

interface Props {
  classes: any
  isReceived: boolean
  timestamp: string
  status: string
  children: ReactElement
}

const MessageWrap: React.FC<Props> = props => {
  const { classes, isReceived, timestamp, children, status } = props

  const renderStatus = (status: string) => {
    if (status === DELIVERED) {
      return <DeliveredStatusIcon />
    } else if (status === READ) {
      return <ReadStatusIcon />
    }

    return <SentStatusIcon />
  }

  return (
    <div className={isReceived ? classes.containerReceived : classes.containerSend}>
      {children && (
        <div className={classes.statusWrap}>
          {timestamp && <span className={classes.timeZone}>{timestamp}</span>}
          {isReceived === false && renderStatus(status)}
        </div>
      )}
      {children}
    </div>
  )
}

export default withStyles(styles)(MessageWrap)
