import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { setSearchAtoms } from '../actions/searchAtoms'
import { editAtom } from '../actions/atom'
import { getFlows } from './flow'
import { BASE_URL } from '../../../configs'
import { formatAtom } from '../../../helpers/atomFormat'

async function createNewAtom(atom, botId, flowId) {
  try {
    await fetch(BASE_URL + '/atom', {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(atom),
    })

    getFlows(botId);
    alertSuccess('Atom is created successfully.')
  } catch {
    alertError('Fail. Try it later')
  }
}

async function saveAtom(atom, botId, language, flowId, callback) {
  try {
    const response = await fetch(`${BASE_URL}/atom?language=${language}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'PUT',
      body: JSON.stringify(atom),
    })

    if (response.status === 400) {
      const text = await response.text()
      const res = JSON.parse(text)
      alertError(res.fieldErrors[0]?.message || res.error?.message)
    } else {
      const responseJson = await logoutOnRequestOrResponseJson(response)
      if (responseJson?.data) {
        alertSuccess('Atom is updated successfully.')
        const formattedAtom = formatAtom(responseJson?.data)
        editAtom(formattedAtom)
        window.location.href.includes('diseases') ? getFlow(flowId, botId) : getFlows(botId);
        callback(formattedAtom)
      } else if (responseJson.error?.message) {
        alertError(responseJson.error?.message)
      }
    }
  } catch {
    alertError('Fail. Try it later')
  }
}

async function getAtom(atomId, language, callback) {
  try {
    const response = await fetch(
      `${BASE_URL}/atom/${atomId}?language=${language}`,
      {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    const responseJson = await logoutOnRequestOrResponseJson(response)
    const formattedAtom = formatAtom(responseJson?.data)

    if (callback) callback(formattedAtom)
    editAtom(formattedAtom)
  } catch {
    alertError('Fail. Try it later')
  }
}

async function deleteAtom(atomId, botId) {
  try {
    const response = await fetch(`${BASE_URL}/atom/${atomId}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'DELETE',
    })
    if (response.ok) {
      getFlows(botId)
      alertSuccess('Atom is deleted succesfully')
    } else {
      const data = await response.json()
      return data.data
    }
  } catch {
    alertError('Fail. Try it later')
  }
}

export function getAtomsWithDisease(atomId, botId) {
    return fetch(`${BASE_URL}/diseases/bot/${botId}/atom/${atomId}/`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then(json => json);
}

export function getAtomsWithConditions(atomId, botId) {
    return fetch(`${BASE_URL}/bot/${botId}/condition/atom/${atomId}/`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then(json => json);
}

async function searchAtomsByContent(searchQuery) {
  try {
    const response = await fetch(
      `${BASE_URL}/atom/search?query=${searchQuery}`,
      {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    const responseJson = await logoutOnRequestOrResponseJson(response)
    setSearchAtoms(responseJson?.data)
  } catch {
    alertError('Fail. Try later')
  }
}

async function testApiRequest(body, userId) {
  try {
    const response = await fetch(`${BASE_URL}/api/call/${userId}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    })

    return await logoutOnRequestOrResponseJson(response)
  } catch {
    alertError('Fail. Try later')
  }
}

export {
  createNewAtom,
  saveAtom,
  getAtom,
  deleteAtom,
  searchAtomsByContent,
  testApiRequest,
}
