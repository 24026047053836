import React, {useState} from 'react';
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Modal from "../../../uiKit/Modal";
import Input from "../../../uiKit/inputs/Input";
import SubmitButton from "../../../uiKit/buttons/SubmitButton";
import {ifStringEmpty} from "../../../static";
import {createNewAtom} from "../../flowBuilder/api/atom";
import LoaderSmall from "../../../uiKit/loaders/loaderSmall";
import {alertError} from "../../../api";

import { useConditionsContext } from "./context";

const styles = theme => ({
    button: {
        border: '1px solid #1658F3',
        borderRadius: 4,
        color: '#1658F3',
        padding: '10px 14px',
        outline: 0,
        fontSize: 14,
        lineHeight: '17px',
        cursor: 'pointer',
        marginBottom: 12
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 35,
        cursor: 'pointer',
        '&>button:first-child': {
            marginRight: 16
        }
    }

});


const AddNewAtom = props => {
    const {flowId, match, showFaq, classes} = props;
    const [ open, setOpen ] = useState(false);
    const [ showLoader, setShowLoader ] = useState(false);
    const [ name, setName ] = useState("");
    const { getFlowCondition } = useConditionsContext();


    const closeModal = () => {
        setOpen(false)
    };

    const openModal = () => {
        setOpen(true)
    };


    const changeName = (value) => {
        setName(value)
    };


    const createAtom = () => {
        if (!ifStringEmpty(name)) {
            let newAtom = {
                name: name,
                flowId: flowId,
                additionalProperties: {
                    isDiseaseAtom: true,
                    isFAQ: showFaq
                }
            };

            if(props.isConditionAtom){
                newAtom.additionalProperties = {
                    isConditionAtom: props.isConditionAtom
                }
            }

            setShowLoader(true);
            createNewAtom(newAtom, match.params.botId, flowId)
                .then(()=>{
                    setName("");
                    setShowLoader(false);
                    closeModal();
                    if(window.location.href.includes('conditions')) {
                        getFlowCondition(flowId, match.params.botId);
                    }
                });
        }
        else{
            alertError("You can't create atom with empty name")
        }
    };

    return (
      <div>
          <button onClick={openModal} className={classes.button}>
              + Add a new {showFaq ? 'faq' : 'script'} atom
          </button>
          <Modal
            open={open}
            onClose={closeModal}
            title="Add new atom"
          >
              {!showLoader ? <div>
                    <Input
                      onChange={e => changeName(e.target.value)}
                      value={name}
                      title='New atom name'
                      placeholder='Enter new atom name'/>
                    <div className={classes.buttonContainer}>
                        <SubmitButton size='lg' title='Create' onClick={createAtom}/>
                        <SubmitButton size='lg' title='Cancel' onClick={closeModal} empty={true}/>
                    </div>
                </div>
                : <LoaderSmall showLoader={showLoader}/>}
          </Modal>
      </div>
    )
}


export default withRouter(withStyles(styles, {withTheme: true})(AddNewAtom));

