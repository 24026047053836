import {
  SAVE_MESSAGE,
  SAVE_ACTIVE_SUPPORT_USER,
  SAVE_SUPPORT_USER_MESSAGES,
  SAVE_SUPPORT_USER_MORE_MESSAGES,
  UPDATE_SUPPORT_MESSAGE_STATUS, CLEAR_ACTIVE_USER,
} from '../actions/activeUser'
import _ from 'lodash'

const initialState = {
  botActive: null,
  chatId: null,
  chatStatus: null,
  firstName: null,
  lastMessage: null,
  lastName: null,
  timestamp: null,
  openedAt: null,
  platform: null,
  postback: null,
  profilePic: null,
  supportRequest: null,
  unreadMessage: null,
  unreadMessages: null,
  userId: null,
  userMessageDatetime: null,
  messages: [],
}

const updateMessage = (messages, message) => {
  let messagesCopy = _.cloneDeep(messages)

  messagesCopy = messagesCopy.map(msg => {
    if (msg?.nativeMessage?.includes(message.status.messageId)) {
      msg.status = message.status.status
    }
    return msg
  })
  return messagesCopy
}

const activeUser = (state = initialState, { type, payload }) => {
  switch (type) {
  case SAVE_ACTIVE_SUPPORT_USER:
    return {
      ...state,
      ...payload.activeUser,
      messages: state.messages,
    }

  case SAVE_SUPPORT_USER_MESSAGES:
    return {
      ...state,
      messages: payload.messages,
    }

  case SAVE_SUPPORT_USER_MORE_MESSAGES:
    return {
      ...state,
      messages: [...state.messages, ...payload.messages],
    }

  case SAVE_MESSAGE:
    return {
      ...state,
      messages: [payload.message, ...state.messages],
    }

  case UPDATE_SUPPORT_MESSAGE_STATUS:
    return {
      ...state,
      messages: [...updateMessage(state.messages, payload.message)],
    }

  case CLEAR_ACTIVE_USER :
    return {
      ...initialState
    }

  default:
    return state
  }
}

export default activeUser
