import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'

import { TreeDotsIcon } from '../../../../uiKit/icons/TreeDotsIcon'
import { closeUserRequest } from '../../api/user'
import { saveActiveUser } from '../../actions/activeUser'

import { styles } from './styles'
import Search from '../Search/Search'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'

const CloseRequestButton = props => {
  const { classes, flows, user, botId, isDisconnectedUser } = props
  const [isOpenPopover, setOpenPopover] = useState(false)
  const [target, setTarget] = useState(null)
  const [atomsList, setAtomsList] = useState([])
  const [search, setSearch] = useState('')
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const atoms = []

    flows.forEach(flow => {
      flow.atoms != null &&
        flow.atoms.forEach(atom => {
          atoms.push({
            value: atom.name,
            id: atom.id,
          })
        })
    })

    setAtomsList(atoms)
  }, [flows])

  const handleCloseRequest = atomId => {
    const backToBot = { userId: user.userId }
    const userCopy = Object.assign({}, user)

    if (atomId && typeof atomId === 'number') backToBot.atomId = atomId

    userCopy.supportRequest = {
      support: {},
      userId: user.userId,
    }

    setLoader(true)
    closeUserRequest(backToBot, botId, user.chatId).then(() => {
      setLoader(false)
      saveActiveUser(userCopy)
    })
  }

  const handleFilterAtoms = atom => {
    if (isStringEmpty(search)) return true
    return atom?.value.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <div className={classes.container}>
      <button
        className={classes.closeButton}
        disabled={loader}
        onClick={handleCloseRequest}>
        Close request
      </button>

      {!isDisconnectedUser && (
        <div
          className={loader ? classes.iconWrapDisabled : classes.iconWrap}
          ref={node => setTarget(node)}
          onClick={() => !loader && setOpenPopover(!isOpenPopover)}>
          <TreeDotsIcon />
        </div>
      )}

      <Popover
        open={isOpenPopover}
        anchorEl={target}
        elevation={2}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setOpenPopover(!isOpenPopover)}>
        <div className={classes.popover}>
          <div className={classes.searchContainer}>
            <Search
              styles={{ border: 'none' }}
              search={search}
              handleSearch={setSearch}
              placeholder="Search atom"
            />
          </div>
          {atomsList.filter(handleFilterAtoms).map(atom => (
            <div
              key={atom.id}
              className={classes.atomItem}
              onClick={() => handleCloseRequest(atom.id)}>
              {atom.value}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  )
}

CloseRequestButton.propTypes = {
  classes: PropTypes.object,
  flows: PropTypes.object,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  flows: state.flows,
  user: state.activeUser,
  botId: state.activeBot?.id,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(CloseRequestButton)),
)
