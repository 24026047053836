import React, { memo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Message from '../Message'

import { styles } from './styles'

const MessageGroup = props => {
  const { classes, messages, date, messageIntoView, messageId, reverse, hash } = props

  const setRef = id => {
    return id?.toString() === hash?.substring(1) || id === messageId ? messageIntoView : null
  }

  const renderMessages = messages
    ?.slice()
    ?.reverse()
    ?.filter(msg => msg.timestamp.split('T')[0] === date)

  return (
    <div className={classes.container}>
      {renderMessages &&
        renderMessages.map((message, index) => (
          <div ref={setRef(message.id)} key={message.id}>
            <Message
              msg={message}
              reverse={reverse}
              highlight={message.id === messageId}
              showConversationId={message?.conversationId !== renderMessages[index - 1]?.conversationId}
            />
          </div>
        ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
}

export default memo(withStyles(styles)(MessageGroup))
