import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WebviewItem from './WebviewItem'
import NoWebview from './NoWebview'
import CreateWebview from './CreateWebview'
import { deleteWebview, getWebviews } from '../api/webview'
import CreateWebviewTemplate from './CreateWebviewTemplate'

import Headline from '../../../uiKit/texts/Headline'
import Modal from '../../../uiKit/Modal'
import SubmitButton from '../../../uiKit/buttons/SubmitButton'
import LoaderSmall from '../../../uiKit/loaders/loaderSmall'
import { LinkTemplateModalProvider } from '../../../contexts/LinkTemplateModalProvider/LinkTemplateModalProvider'
import LaguageSelector from '../../../uiKit/LaguageSelector/LaguageSelector'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  webviewList: {
    marginTop: 15,
    borderRadius: 10,
    justifyContent: 'center',
    flexBasis: '100%',
    marginRight: 40,
  },
  webviewListTitle: {
    fontSize: 18,
    lineHeight: '22px',
    color: '#3A3F62',
    fontWeight: 'bold',
  },
  itemColumnHeading: {
    display: 'flex',
    marginTop: 20,
    borderBottom: '1px solid #0000001a',
    padding: '24px',
    justifyContent: 'flex-start',
    '&>div': {
      flexBasis: '35%',
      fontSize: 16,
      lineHeight: '19px',
      color: '#3A3F62',
    },
    '&>div:last-child': {
      flexBasis: '50%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '&>div:last-child': {
      marginLeft: 24,
    },
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '24px 70px',
  },
})

class WebviewBuilder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      webviews: [],
      openDeleteModal: false,
      showLoader: false,
      selectedLanguage: {label: 'English', value: 'en'},
    }
  }

  componentDidMount() {
    getWebviews(this.props.match.params.botId, this.state.selectedLanguage.value)
  }

  static getDerivedStateFromProps(props) {
    return {
      webviews: props.webviews,
    }
  }

  handleChangeLanguage = (language) => {
    this.setState({
      selectedLanguage: language
    }, () => {
      getWebviews(this.props.match.params.botId, this.state.selectedLanguage.value)
    })
  }

  deleteWebview = id => {
    deleteWebview(id, this.props.match.params.botId).then(this.closeModal)
  }

  openModal = name => {
    this.setState({
      [name]: true,
    })
  }

  openDeleteModal = id => {
    this.setState({
      openDeleteModal: true,
      activeWebviewId: id,
    })
  }

  closeModal = name => {
    this.setState({
      [name]: false,
    })
  }

  updateWebviews = (webview, index) => {
    const updatedWebviews = [...this.state.webviews]

    updatedWebviews[index] = webview

    this.setState({
      webviews: updatedWebviews,
    })
  }

  selectWebview = id => {
    this.setState({
      activeWebviewId: id,
      openEditModal: true,
    })
  }

  render() {
    const { classes } = this.props
    const {
      webviews,
      showLoader,
      openDeleteModal,
      openEditModal,
      activeWebviewId,
      selectedLanguage
    } = this.state
    const language = selectedLanguage.value

    return (
      <div>
        <div className={classes.header}>
          <Headline title="Webview" />
          <LaguageSelector onChange={this.handleChangeLanguage} value={this.state.selectedLanguage}/>
          {webviews.length !== 0 &&
            <CreateWebview
              language={language}
            />}
        </div>
        {webviews.length > 0 ? (
          <div className={classes.container}>
            <div className={classes.webviewList}>
              <div className={classes.itemColumnHeading}>
                <div>Name</div>
                <div>URL</div>
              </div>
              <div>
                {webviews.map((webview, idx) => (
                  <div key={webview.id}>
                    <WebviewItem
                      selectWebview={() => this.selectWebview(webview.id)}
                      webview={webview}
                      key={webview.id}
                      deleteWebview={() => this.openDeleteModal(webview.id)}
                    />
                    <Modal
                      open={openDeleteModal && activeWebviewId === webview.id}
                      onClose={() => this.closeModal('openDeleteModal')}
                      title={`Are you sure you want to delete ${webview.name} webview?`}>
                      {!showLoader ? (
                        <div className={classes.buttonsHolder}>
                          <SubmitButton
                            onClick={() => this.deleteWebview(webview.id)}
                            title="Delete"
                          />
                          <SubmitButton
                            onClick={() => this.closeModal('openDeleteModal')}
                            empty
                            title="Cancel"
                          />
                        </div>
                      ) : (
                        <LoaderSmall showLoader={showLoader} />
                      )}
                    </Modal>
                    <LinkTemplateModalProvider>
                      <Modal
                        open={openEditModal && activeWebviewId === webview.id}
                        onClose={() => this.closeModal('openEditModal')}
                        title="Edit webview">
                        <CreateWebviewTemplate
                          webview={webview}
                          language={language}
                          updateWebviews={this.updateWebviews}
                          idxWebview={idx}
                          onClose={() => this.closeModal('openEditModal')}
                        />
                      </Modal>
                    </LinkTemplateModalProvider>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <NoWebview
            language={language}
          />
        )}
      </div>
    )
  }
}

WebviewBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    webviews: state.webviews,
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(WebviewBuilder),
  ),
)
