import React from 'react';


export const DiseaseIcon = () => {
    return (
        <svg className='deseaseIcon' width="29" height="29" viewBox="0 0 29 29" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.3446 14.7334L15.0639 14.7211L15.031 17.3078C15.0067 19.195 14.6194 21.0379 13.8796 22.785C13.1394 24.5331 12.014 26.1698 10.4384 27.7454C10.1408 28.043 9.66585 28.0506 9.37765 27.7624C9.0896 27.4744 9.09721 26.9995 9.39485 26.7018L9.89047 26.2021L2.08214 18.3938L1.58244 18.8894C1.2848 19.1871 0.809897 19.1947 0.521704 18.9065C0.233512 18.6183 0.241255 18.1435 0.538897 17.8459C2.11449 16.2703 3.75103 15.1447 5.4991 14.4048C7.24635 13.6649 9.08926 13.2776 10.9764 13.2534L13.5631 13.2203L13.5507 10.9395C13.5404 9.00764 13.9111 7.11756 14.6523 5.32215C15.394 3.52563 16.4685 1.91629 17.8457 0.539027C18.1434 0.241385 18.6183 0.233502 18.9065 0.521694C19.1947 0.809887 19.1869 1.28493 18.8893 1.58257C18.7459 1.72592 18.6063 1.87211 18.4703 2.02085L26.2633 9.81386C26.4122 9.678 26.5583 9.53833 26.7017 9.39498C26.9993 9.09734 27.4742 9.08945 27.7624 9.37764C28.0506 9.66584 28.0429 10.1409 27.7452 10.4385C26.368 11.8158 24.7586 12.8903 22.9621 13.632C21.1667 14.3731 19.2766 14.7438 17.3446 14.7334ZM3.24542 17.4012L10.8831 25.0388C11.193 24.6295 11.4781 24.2029 11.7376 23.7599L4.52434 16.5467C4.08139 16.8062 3.65471 17.0912 3.24542 17.4012ZM5.95546 15.8219L12.4623 22.3288C12.6546 21.8744 12.8589 21.3225 12.9978 20.8546L7.42964 15.2864C6.9618 15.4254 6.40984 15.6297 5.95546 15.8219ZM9.19373 14.8946L13.3896 19.0905L13.5642 14.72L9.19373 14.8946ZM15.0635 13.2207L18.984 13.1453L15.139 9.30029L15.0635 13.2207ZM15.4841 7.48953L20.7947 12.8002C21.3046 12.6646 21.8078 12.4973 22.3033 12.2987L15.9855 5.98101C15.7868 6.47633 15.6197 6.97967 15.4841 7.48953ZM17.5045 3.211C17.2036 3.62994 16.928 4.06598 16.6785 4.51842L23.7658 11.6057C24.2181 11.3561 24.6543 11.0806 25.0731 10.7796L17.5045 3.211Z"
                fill="#8EB0F8"/>
        </svg>

    );
};