import React from "react";

// refactor when ConditionsTab will be Functional component
import { ConditionsTab } from "./index";
import { useConditionsContext } from "../context";

export const ConditionsTabContexts = () => {
  const { conditions, getConditions, getFlowCondition, deleteCondition, updateConditionAtom } = useConditionsContext();

  return (
    <ConditionsTab
      conditions={conditions}
      getConditions={getConditions}
      getFlowCondition={getFlowCondition}
      updateConditionAtom={updateConditionAtom}
      deleteConditionFromState={deleteCondition}
    />
  )
}
