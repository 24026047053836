import {CREATE_WEBVIEW, DELETE_WEBVIEW, UPDATE_WEBVIEW, UPDATE_WEBVIEWS} from "../actions";

const webviews = (state = [], action) => {
    let webviews = [...state];
    let webview = action.webview;
    let webviewIndex = webview && webviews.findIndex(item => item.id === webview.id);
    switch (action.type) {
        case CREATE_WEBVIEW:
            webviews.push(action.webview);
            return webviews;
        case DELETE_WEBVIEW:
            webviews.splice(webviewIndex, 1);
            return webviews;
        case UPDATE_WEBVIEWS:
            return action.webviews;
        case UPDATE_WEBVIEW:
            webviews[webviewIndex]=webview;
            return webviews;
        default:
            return state;
    }
};

export default webviews