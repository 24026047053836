export const styles = () => ({
  container: {
    width: 124,
    height: 36,
    backgroundColor: '#cccccc',
    color: '#ffffff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'auto',
    margin: '0px 12px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    userSelect: 'none'
  },
})
