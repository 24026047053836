import { useState, useEffect } from "react";
import {getTypeConditionFlowForDropdowns} from "./api/Conditions";

export const useDropdownsSettings = botId => {
  const [dropdownsSettings, setDropdownsSettings] = useState({});

  useEffect(() => {
    getDropdownsSettings()
  }, [])

  const getDropdownsSettings = () => {
    getTypeConditionFlowForDropdowns(botId)
      .then(response => setDropdownsSettings(response))
  }

  return ({
    dropdownsSettings,
    getDropdownsSettings
  })
}
