import { withStyles } from '@material-ui/core'
import React from 'react'
import CreateWebview from './CreateWebview'
import { TabletImage } from '../../../uiKit/icons/Icons'

const styles = theme => ({
  container: {
    paddingTop: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paragraph: {
    fontSize: 14,
    lineHeight: '150%',
    color: '#616581',
    marginTop: 16,
  },
})

class NoWebview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, language } = this.props
    return (
      <div className={classes.container}>
        <TabletImage />
        <p className={classes.paragraph}>You have no webviews yet.</p>
        <CreateWebview
          language={language}
        />
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(NoWebview)
