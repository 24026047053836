import { BLUE } from 'constants/colors'

export const createATag = ({ dataHref, dataExtension, innerText, onclick }) => {
  const aLink = document.createElement('a')
  aLink.className = 'linkEdited'
  aLink.setAttribute('tabindex', '0')
  aLink.setAttribute('data-href', `${dataHref}`)
  aLink.setAttribute('contenteditable', 'false')
  aLink.style.color = BLUE
  aLink.id = '' + Date.now()
  aLink.setAttribute('data-extension', `${dataExtension}`)
  aLink.innerText = innerText
  aLink.onclick = onclick

  return aLink
}
