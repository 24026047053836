import React from 'react';
import {withStyles} from "@material-ui/core";
import {PropTypes} from "prop-types";
const styles = theme => ({
    button: {
        fontSize: 14,
        color: '#3A3F62',
        display: 'flex',
        alignItems: 'center',
        padding: '11px 24px',
        height: '33.33%',
        cursor:'pointer',
        '&:hover': {
            background: 'rgba(90, 152, 247, 0.1)'
        }
    },
});

const ButtonPopover = (props) =>{
    const {classes, onClick, title} = props;

    return(
            <div onClick={onClick}
                 className={classes.button}
                 data-testid='popover-button'>{title}
            </div>
    )
};


ButtonPopover.propTypes={
    classes:PropTypes.object.isRequired,
    title:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
};


export default withStyles(styles, {withTheme: true})(ButtonPopover);
