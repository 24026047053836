import {store} from '../../../index'

export const CREATE_WEBVIEW = 'CREATE_WEBVIEW';
export const UPDATE_WEBVIEWS = 'UPDATE_WEBVIEWS';
export const UPDATE_WEBVIEW = 'UPDATE_WEBVIEW';
export const DELETE_WEBVIEW = 'DELETE_WEBVIEW';

export const addWebview = (webview) => (
  store.dispatch(
    {
      type: CREATE_WEBVIEW,
      webview
    }
  )
);

export const updateWebviews = (webviews) => (
  store.dispatch(
    {
      type: UPDATE_WEBVIEWS,
      webviews
    }
  )
);

export const findAndUpdateWebview = (webview) => (
  store.dispatch(
    {
      type: UPDATE_WEBVIEW,
      webview
    }
  )
);