import styled from 'styled-components'

const DropdownWrapper = styled.div`
  position: relative;
`

const DropdownButton = styled.div`
  background-color: ${props => props.isInitializedDropdown ? '#ffffff' : '#5A98F7'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.isInitializedDropdown ? '#2B57EA' : '#ffffff'} ;
  font-weight: 500;
  padding: 6px;
  text-align: center;
  cursor: pointer;
  margin: auto;
  border-radius: 0;
  
  &:hover {
    color: #0084ff;
    background: white;
  }
  
  & svg {
    margin-right: 4px
  }
`

export { DropdownButton, DropdownWrapper }
