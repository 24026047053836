import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import OperatorSelect from '../OperatorSelect/OperatorSelect'
import InputAutosuggest from '../../../../uiKit/InputAutosuggest/InputAutosuggest'
import ConjunctionSelect from '../ConjunctionSelect/ConjunctionSelect'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'
import { usePrevious } from '../../../../hooks/usePrevious'
import {
  CHATBOT,
  DEFAULT,
  FACEBOOK,
  TWILIO,
  WHATSAPP,
  WIDGET,
} from '../../../../constants/attributeTypes'

const ConditionExpression = props => {
  const {
    classes,
    expression,
    onDelete,
    onUpdate,
    conjunction,
    onUpdateConjunction,
    isLast,
    onAdd,
    isOne,
    saveTime,
  } = props
  const [focus, setFocus] = useState(null)
  const [touched, setTouched] = useState({ attribute: false, value: false })
  const prevSaveTime = usePrevious(saveTime)

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      const touched = {
        attribute: true,
        value: true,
      }

      setTouched(touched)
    }
  }, [saveTime])

  useEffect(() => {
    if (isOne) {
      onUpdateConjunction({ value: 'AND', label: 'and' })
    }
  }, [isOne])

  const handleUpdateAttribute = e => {
    const expressionCopy = deepCopyFunction(expression)

    expressionCopy.attribute = { id: e?.value || null, name: e?.label || null }

    onUpdate(expressionCopy)
    setFocus(false)
  }

  const handleUpdateOperator = e => {
    const expressionCopy = deepCopyFunction(expression)
    expressionCopy.operator = e.value

    if (['IS_DEFAULT', 'IS_NOT_DEFAULT'].includes(expression?.operator)) {
      expressionCopy.value = null
    }

    onUpdate(expressionCopy)
  }

  const handleUpdateAttributeValue = e => {
    const expressionCopy = deepCopyFunction(expression)
    expressionCopy.value = e.target.value || e.target.innerText

    onUpdate(expressionCopy)
  }

  const updateTouched = (field, isTouched) => {
    const touchedCopy = deepCopyFunction(touched)
    touchedCopy[field] = isTouched

    setTouched(touchedCopy)
  }

  return (
    <div className={classes?.container}>
      <AttributeSelect
        focus={focus || !expression?.attribute?.id}
        value={expression?.attribute?.id}
        onBlur={() => setFocus(false)}
        onClick={() => setFocus(true)}
        onChange={handleUpdateAttribute}
        required={true}
        styles={{ borderRadius: 10, padding: '2px 0px' }}
        containerStyle={{ minWidth: 112, width: 'calc(50% - 97px)' }}
        placeholder={'Select attribute'}
        displayOptions={[DEFAULT, CHATBOT, WIDGET, FACEBOOK, WHATSAPP, TWILIO]}
        defaultAttributes={['language', 'platform', 'close_conversation', 'conversation_outcome']}
        isClearable={true}
        onFocus={() => updateTouched('attribute', true)}
        touched={touched?.attribute}
        saveTime={saveTime}
      />

      <OperatorSelect
        value={expression?.operator}
        onChange={handleUpdateOperator}
      />

      {!['IS_DEFAULT', 'IS_NOT_DEFAULT'].includes(expression?.operator) && (
        <InputAutosuggest
          attributeId={expression?.attribute?.id}
          value={expression?.value}
          placeholder={'Value'}
          containerStyle={{
            borderRadius: 10,
            height: 42,
          }}
          error={touched.value && !expression?.value}
          menuStyle={{ top: 48 }}
          style={{ minWidth: 90, width: 'calc(50% - 97px)' }}
          onChange={handleUpdateAttributeValue}
          onBlur={() => updateTouched('value', true)}
        />
      )}

      {isLast ? (
        <div className={classes?.addButton} onClick={onAdd}>
          <PlusIcon />
        </div>
      ) : (
        <ConjunctionSelect value={conjunction} onChange={onUpdateConjunction} />
      )}

      {!isOne && (
        <div className={classes?.iconWrap} onClick={onDelete}>
          <TrashIcon />
        </div>
      )}
    </div>
  )
}

ConditionExpression.propTypes = {
  classes: PropTypes.object,
}

export default React.memo(withStyles(styles)(ConditionExpression))
