import React, { MouseEventHandler, ReactNode } from 'react'
import classes from './styles.module.scss'
import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'
import { IconButton } from '@material-ui/core'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'

interface Props {
  tooltipText: string
  title: string
  children: ReactNode
  headerButton?: () => void
  onDelete: MouseEventHandler<HTMLElement>
}

const MessageTemplate: React.FC<Props> = ({ tooltipText, title, children, onDelete, headerButton }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <>
          <span>
          {title}
            <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={tooltipText} />
          </span>
          {headerButton}
        </>
      </div>

      {children}

      {onDelete && (
        <i className={classes.deleteTemplate}>
          <IconButton className={classes.deleteIcon} aria-label="Delete" onClick={onDelete}>
            <TrashIcon width="20px" height="20px" color="#B0B2C0" />
          </IconButton>
        </i>
      )}
    </div>
  )
}

export default MessageTemplate
