import { CLEAR_ATOM, SAVE_ATOM } from '../actions/atom'

const atom = (state = [], action) => {
  switch (action.type) {
    case SAVE_ATOM:
      return action.atom
    case CLEAR_ATOM:
      return null
    default:
      return state
  }
}

export default atom
